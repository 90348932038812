import React, { useState } from "react";
import "../../style/pricing_page/pricing_page.css";
import logo from "../../assets/favicon.png";
import "../../style/navbar/navbar.css";
import close from "../../assets/landing_page/close.svg";
import { PopupModal } from "react-calendly";

class CalendlyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div>

        <div className="pricing-contact-us-started" onClick={() => this.setState({ isOpen: true })}>
          Get Started
        </div>

        <PopupModal
          url="https://calendly.com/butterflylearnings/30min"
          pageSettings={this.props.pageSettings}
          utm={this.props.utm}
          prefill={this.props.prefill}
          onModalClose={() => this.setState({ isOpen: false })}
          open={this.state.isOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
      </div>
    );
  }
}

const ContactUsSection = ({overlayActive, setOverlayActive}) => {

  // const [overlayActive, setOverlayActive] = useState(false);

  const toggleOverlay = () => {
    setOverlayActive(!overlayActive);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>

    <div className="pricing-contact-us-div">

        <img src={logo} alt="" width={85} height={90}/>

        <div className="pricing-contact-us-heading">
            Work better with Benchmark
        </div>

        <div className="pricing-contact-us-subheading">
            See why millions of people use Benchmark
        </div>

        <div className="pricing-contact-us-row">

            <CalendlyButton />

            {/* <div className="pricing-contact-us-started">
                Get Started
            </div> */}


            <div className="pricing-contact-us-contact" onClick={() => {toggleOverlay()}}>
                Contact Sales
            </div>

        </div>

        {
        overlayActive 
          ? (
              <div className="contact-us-center">
                <div className="contact-us-overlay">


                  <div className="contact-us-overlay-row-3">
                    <div className="contact-us-overlay-heading">
                      Contact Us
                    </div>

                    <img
                      src={close}
                      alt=""
                      className="contact-us-overlay-icon"
                      onClick={() => {
                        toggleOverlay();
                    }}
                  />

                  </div>

                  <div className="contact-us-overlay-subheading">
                    If you are interested in using Benchmark, fill out the form below and our team will reach out to you. 
                  </div>

                  <div className="contact-us-overlay-label">
                    First Name*
                  </div>
                  
                  <input
                    type="text"
                    className="contact-us-overlay-textfield"
                    placeholder=""
                  />

                  <div className="contact-us-overlay-label">
                    Last Name*
                  </div>
                  
                  <input
                    type="text"
                    className="contact-us-overlay-textfield"
                    placeholder=""
                  />

                  <div className="contact-us-overlay-label">
                    Phone Number*
                  </div>

                  <div className="contact-us-overlay-row">

                    <div className="contact-us-overlay-textfield-2">
                      <div className="contact-us-country-code">
                        +91
                      </div>
                    </div>

                    <input
                      type="text"
                      className="contact-us-overlay-textfield-1"
                      placeholder=""
                    />

                  </div>

                  <div className="contact-us-overlay-label">
                    Company Email*
                  </div>
                  
                  <input
                    type="text"
                    className="contact-us-overlay-textfield"
                    placeholder=""
                  />

                  <div className="contact-us-overlay-label">
                    Business Address Line 1  (Optional) 
                  </div>
                  
                  <input
                    type="text"
                    className="contact-us-overlay-textfield"
                    placeholder=""
                  />

                  <div className="contact-us-overlay-label">
                    Business Address Line 2 
                  </div>
                  
                  <input
                    type="text"
                    className="contact-us-overlay-textfield"
                    placeholder=""
                  />

                <div className="contact-us-overlay-row-1">

                  <div>
                    <div className="contact-us-overlay-label">
                      Pincode
                    </div>

                    <input
                      type="text"
                      className="contact-us-overlay-textfield"
                      placeholder=""
                    />
                  </div>

                  <div>
                    <div className="contact-us-overlay-label">
                      City
                    </div>

                    <input
                      type="text"
                      className="contact-us-overlay-textfield"
                      placeholder=""
                    />
                  </div>

                  <div>
                    <div className="contact-us-overlay-label">
                      State
                    </div>

                    <input
                      type="text"
                      className="contact-us-overlay-textfield"
                      placeholder=""
                    />
                  </div>

                </div>

                  <div className="contact-us-overlay-label" style={{marginTop:24}}>
                    What would you like to discuss?*
                  </div>
                  
                  <input
                    type="text"
                    className="contact-us-overlay-textfield"
                    placeholder=""
                  />

                  <div className="contact-us-overlay-note">
                    Fields marked with an asterisk (*) are required.
                  </div>

                  <div className="contact-us-overlay-row-2">

                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />

                    <div className="contact-us-overlay-terms">
                      By checking the box and clicking "Submit", you are agreeing to Benchmark’s Privacy Statement.
                    </div>

                  </div>

                  <div className="contact-us-overlay-terms">
                    By submitting this form, you agree that Asana may share this information with its authorized Solution Partners, and that you may be contacted about this inquiry by Asana or a partner of Asana.
                  </div>

                  <div className="contact-us-overlay-submit">
                    Submit
                  </div>

                  
                
                </div>
              </div>
            ) 
          : null
        }

    </div>

    </>
  );
};

export default ContactUsSection;