import React, { useState } from "react";
import "../../style/about_page/about_page.css";
import productivity from "../../assets/about_page/productivity.jpg";
import checkbox from "../../assets/about_page/checkbox.png";

const ProductivityHeader = ({ setOverlayActive }) => {

    const handleClick = () => {
        // Call the function passed down as prop to update menuStatus
        setOverlayActive(prevStatus => !prevStatus);
    };

  return (
    <>  

        <div className="productivity-div">

            <div className="productivity-div-row">

                <img src={productivity} alt="" className="productivity-image"/>
                
                <div className="productivity-row-column">

                    <div className="productivity-row-title-column">

                        <div className="productivity-column-title">
                            Work with the team of experts that created an all in one solution for your therapy business needs.
                        </div>

                        <div className="productivity-column-subtitle">
                            The team coordinates work across departments by connecting strategy, planning, and execution in a single platform.
                        </div>

                    </div>

                    <div className="productivity-check-column">

                        <div className="productivity-check-row">

                            <img src={checkbox} alt="" className="productivity-check-image"/>
                            
                            <div className="productivity-check-text">
                                Hipaa compliance
                            </div>

                        </div>

                        <div className="productivity-check-row">

                            <img src={checkbox} alt="" className="productivity-check-image"/>
                            
                            <div className="productivity-check-text">
                                ISO 27001 certified, GDPR 
                            </div>

                        </div>

                        <div className="productivity-check-row">

                            <img src={checkbox} alt="" className="productivity-check-image"/>
                            
                            <div className="productivity-check-text">
                                Automated graphs for data understanding
                            </div>

                        </div>

                        <div className="productivity-contact-button" onClick={handleClick}>
                            Contact Us
                        </div>

                    </div>

                    

                </div>

            </div>
            

        </div>

    </>
  );
};

export default ProductivityHeader;