import React, { useState, useEffect, useRef } from "react";
import "../../style/features_page/features_page.css";
import NavBar from "../../components/navbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TherapyManagement from "../../components/features_page/therapy_management";
import PracticeManagement from "../../components/features_page/practice_management";
import FeatureNewHeader from "../../components/features_page/feature_new_header";
import FeatureShowcase from "../../components/features_page/feature_showcase";
import DownloadSection from "../../components/features_page/download_section";
import BusinessPotential from "../../components/features_page/business_potential";
import Footer from "../../components/footer";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  

const FeaturesPage = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const featuresRef = useRef(null);

  return (
      <>
          <NavBar overlayActive={overlayActive} setOverlayActive={setOverlayActive}/>

          {/* Headers */}
          <FeatureNewHeader setOverlayActive={setOverlayActive}/>

          {/* Feature Block */}
          <div ref={featuresRef}>
              <FeatureShowcase/>
          </div>


          <div className="tabs-section">

              <Box sx={{width: "100%"}}>
                  <Box sx={{borderBottom: 0, borderColor: "divider"}}>
                      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                          <Tab label="Therapy Management" className="tab-label"/>
                          <Tab label="Practice Management" className="tab-label"/>
                      </Tabs>
                  </Box>
              </Box>

              <CustomTabPanel value={value} index={0}>

                  <TherapyManagement/>

              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>

                  <PracticeManagement/>

              </CustomTabPanel>

          </div>

          <DownloadSection/>

          <div className="new-landing-mob-height"></div>

          <BusinessPotential/>

          <Footer setOverlayActive={setOverlayActive}/>


      </>
  );
};

export default FeaturesPage;