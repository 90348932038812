import React from "react";
import "../../../style/new_landing/new_landing_style.css";

const NewLandingPageHeaderTitle = () => {
  return (
      <div className="new-landing-header-title">
          Comprehensive data collection and patient management solution for your ABA therapy organization.
      </div>
  );
};

export default NewLandingPageHeaderTitle;
