import React, { useState, useEffect } from "react";
import "../../style/features_page/features_page.css";
import CalendlyButton from "../calendly";
import FeatureLanding1 from "../../assets/features_page/feature-landing-1.png";
import FeatureLanding2 from "../../assets/features_page/feature-landing-2.png";
import FeatureLanding3 from "../../assets/features_page/feature-landing-3.png";
import barChart from "../../assets/features_page/bar-chart.png";
import clipboard from "../../assets/features_page/clipboard-1.png";
import smartPhone from "../../assets/features_page/smartphone.png";
import landingCheck from "../../assets/landing_page/landing_check.png";

const FeatureNewHeader = ({ setOverlayActive }) => {

  const handleClick = () => {
    // Call the function passed down as prop to update menuStatus
    setOverlayActive(prevStatus => !prevStatus);
  };

  const [currentImage, setCurrentImage] = useState(FeatureLanding1);

  useEffect(() => {
    const interval = setInterval(() => {
      // Change the image every 3 seconds (adjust the interval duration as needed)
      setCurrentImage((prevImage) => prevImage === FeatureLanding1 ? FeatureLanding2 : prevImage === FeatureLanding2 ? FeatureLanding3 : FeatureLanding1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  return (
    <>
 
      <div className="landing-header-container">

        <div className="landing-header-heading">
          Everything Required to Run and Grow Your Business
        </div>

        <div className="landing-header-subheading">
          An end-to-end solution that includes -
        </div>

        <div className="feature-points-row">

          <div className="feature-points-column">
              <div className="feature-points-column-text">
                <img src={landingCheck} alt="" width={24} height={24} style={{paddingRight:12}}/>
                User management 
              </div>

              <div className="feature-points-column-text">
                <img src={landingCheck} alt="" width={24} height={24} style={{paddingRight:12}}/>
                Scheduling pre assessments/therapies 
              </div>

              <div className="feature-points-column-text">
              <img src={landingCheck} alt="" width={24} height={24} style={{paddingRight:12}}/>
                Data analytics tools - which includes automated graphs
              </div>
          </div>

          <div className="feature-points-column">
              <div className="feature-points-column-text">
                <img src={landingCheck} alt="" width={24} height={24} style={{paddingRight:12}}/>
                Billing services
              </div>

              <div className="feature-points-column-text">
                <img src={landingCheck} alt="" width={24} height={24} style={{paddingRight:12}}/>
                Personalized dashboards for therapists
              </div>

              <div className="feature-points-column-text">
                <img src={landingCheck} alt="" width={24} height={24} style={{paddingRight:12}}/>
                A customized parent app with an analytics dashboard for client satisfaction and monitor kid’s performance.
              </div>
          </div>


        </div>

        <div className="feature-landing-button-row">

          <CalendlyButton />

          <div className="feature-landing-contact-btn" onClick={handleClick}>
            Contact Sales
          </div>

        </div>

        <div className="feature-landing-row">
          {currentImage === FeatureLanding3 ?
            <img src={currentImage} alt="" className={`feature-landing-reso-1 fade-in`} />
            :
            <img src={currentImage} alt="" className={`feature-landing-reso fade-in`} />}
        </div>

        <div className="feature-landing-second-row">

          <div className="feature-landing-second-column">

            <img src={barChart} alt="" className="feature-landing-second-img" />

            <div className="feature-landing-second-column-heading">
              Software for Collecting Data Simplified
            </div>

            <div className="feature-landing-second-column-subheading">
              Utilizing technology to simplify your therapy practice, making it easy to store data and generate automated Graphs based on data collected.
            </div>

          </div>

          <div className="feature-landing-second-column-1">

            <img src={clipboard} alt="" className="feature-landing-second-img" />

            <div className="feature-landing-second-column-heading">
              Software for Billing
            </div>

            <div className="feature-landing-second-column-subheading-1">
              With an easy workflow, Benchmark's billing software increases efficiency and automates repetitive tasks, saving your time.
            </div>

          </div>

          <div className="feature-landing-second-column">

            <img src={clipboard} alt="" className="feature-landing-second-img" />

            <div className="feature-landing-second-column-heading">
              Software for Scheduling
            </div>

            <div className="feature-landing-second-column-subheading">
              Adopt the cutting-edge features of Smart Scheduling, which was created especially for better therapy operations and ease of use for staff.
            </div>

          </div>

        </div>

        <div className="feature-landing-second-row">

          <div className="feature-landing-second-column">

            <img src={barChart} alt="" className="feature-landing-second-img" />

            <div className="feature-landing-second-column-heading">
              Efficient update for Role based Access
            </div>

            <div className="feature-landing-second-column-subheading">
              Multiple role based users can access the system and use it efficiently. Based on roles, the access to the system can be modified by the Account manager.
            </div>

          </div>

          <div className="feature-landing-second-column-1">

            <img src={clipboard} alt="" className="feature-landing-second-img" />

            <div className="feature-landing-second-column-heading">
              Data Privacy and Hipaa Compliance
            </div>

            <div className="feature-landing-second-column-subheading-1">
              Rest assured client's data is safe with benchmark as we follow the hipaa compliance data policies. We are also ISO 27001 certified and GDPR compliant
            </div>

          </div>

          <div className="feature-landing-second-column">

            <img src={clipboard} alt="" className="feature-landing-second-img" />

            <div className="feature-landing-second-column-heading">
              Session Summary via WhatsApp
            </div>

            <div className="feature-landing-second-column-subheading">
              Get the insight session summary to make the complex data collection simple via WhatsApp to both therapist and parent.
            </div>

          </div>

        </div>

        <div className="feature-landing-second-row-1">

          <div className="feature-landing-second-column">

            <img src={barChart} alt="" className="feature-landing-second-img" />

            <div className="feature-landing-second-column-heading">
              Customized Parent Dashboard
            </div>

            <div className="feature-landing-second-column-subheading">
              Monitor your kid’s performance using our  mobile application
            </div>

          </div>

        </div>

        {/* <div className="landing-header-link">
                  View Features Below {String.fromCharCode(0x2192)}
              </div> */}

      </div>

    </>
  );
};

export default FeatureNewHeader;