import React, {useState} from "react";
import "../../style/pricing_page/pricing_page.css";
import checkList from "../../assets/pricing_page/check-points.png";
import checkListSvg from "../../assets/pricing_page/check-points.svg";
import "../../style/navbar/navbar.css";
import close from "../../assets/landing_page/close.svg";
import {PopupModal} from "react-calendly";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, {SwitchProps} from '@mui/material/Switch';
import {styled} from '@mui/material/styles';

class CalendlyButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <div className="div-width">

                <div className="book-demo-btn" onClick={() => this.setState({isOpen: true})}>
                    Book a Demo
                </div>

                {/* <div className="google-book-demo" onClick={() => this.setState({ isOpen: true })}>
          <img src={landingCalendar} alt="" className="landing-calendar-button-image" />
          Book A Free Demo
        </div> */}

                <PopupModal
                    url="https://calendly.com/butterflylearnings/30min"
                    pageSettings={this.props.pageSettings}
                    utm={this.props.utm}
                    prefill={this.props.prefill}
                    onModalClose={() => this.setState({isOpen: false})}
                    open={this.state.isOpen}
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("root")}
                />
            </div>
        );
    }
}

class CalendlyButton2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <div className="div-width">

                <div className="get-started-btn" onClick={() => this.setState({isOpen: true})}>
                    Get Started
                </div>

                <PopupModal
                    url="https://calendly.com/butterflylearnings/30min"
                    pageSettings={this.props.pageSettings}
                    utm={this.props.utm}
                    prefill={this.props.prefill}
                    onModalClose={() => this.setState({isOpen: false})}
                    open={this.state.isOpen}
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("root")}
                />
            </div>
        );
    }
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) =>
    ({
        width: 56,
        height: 24,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 3,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(32px)',
                color: '#EDBB01',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#11305C',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#11305C',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#11305C',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 18,
            height: 18,
            color: '#EDBB01',
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#11305C',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D'
            }),
        },
    }));

const PricingPlans = ({setOverlayActive}) => {

    const [isMonthly, setIsMonthly] = useState(false);

    const handleSwitchChange = (event) => {
        setIsMonthly(event.target.checked);
    };

    // const [overlayActive, setOverlayActive] = useState(false);

    // const toggleOverlay = () => {
    //   setOverlayActive(!overlayActive);
    // };

    // const [isChecked, setIsChecked] = useState(false);

    // const handleCheckboxChange = (event) => {
    //   setIsChecked(event.target.checked);
    // };

    const handleClick = () => {
        // Call the function passed down as prop to update menuStatus
        setOverlayActive(prevStatus => !prevStatus);
    };


    const [isOn, setIsOn] = useState(false);

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };


    return (
        <>

            <div className="pricing-plans-header">

                <div className="pricing-plans-column">
                    <div className="pricing-heading-column">
                        {/*<div className="pricing-plans-preheading">*/}
                        {/*    PRICING*/}
                        {/*</div>*/}

                        <div className="pricing-plans-heading">
                            The Right Plan for Your Business
                        </div>

                        <div className="pricing-plans-subheading">
                            We have several powerful plans to showcase your business and get discovered as a creative
                            entrepreneurs. Everything you need.
                        </div>

                        <div className="pricing-switch-row">

                            <div className={isMonthly ? "pricing-switch-row-normal" : "pricing-switch-row-bold"} style={{paddingRight: '24px'}}>
                                Bill Monthly
                            </div>

                            <FormControlLabel
                                control={<IOSSwitch sx={{m: 1}}/>}
                                // label="iOS style"
                                checked={isMonthly}
                                onChange={handleSwitchChange}
                            />

                            <div className={isMonthly ? "pricing-switch-row-bold" : "pricing-switch-row-normal"}>
                                Bill Annually
                            </div>
                        </div>
                    </div>


                </div>


                <div className="pricing-model-row">

                    <div className="pricing-model-card">

                        {/*<div className="pricing-card-best">*/}
                        {/*    COMPLETELY FREE*/}
                        {/*</div>*/}

                        <div className="pricing-card-name">
                            Free
                        </div>

                        {/*<div className="pricing-card-prices">*/}
                        {/*    $ 0/month*/}
                        {/*</div>*/}

                        {/*<div className="pricing-card-prices-subheading">*/}
                        {/*    Per learner, per month billed annually*/}
                        {/*</div>*/}

                        {/*<div className="pricing-card-billed" style={{paddingTop: 10, color: '#FFF'}}>*/}
                        {/*    $ 0 billed monthly*/}
                        {/*</div>*/}

                        <div className="check-list-column">

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Manage up to 2 Clients
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Unlimited Therapist Dashboard
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Unlimited Library Management
                            </div>

                            {/* <div className="check-list-row">
                            <img src={checkList} alt="" width={18} height={18}/>
                            Accommodates 50+ clients for multiple organizational centers
                        </div> */}

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Session Scheduling
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Attendance View Tracking (for up to 2 Clients)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Session Data Collection ( 2 Clients - 3 months)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Therapy Training (access to 3 courses)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Session Management
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Billing with invoice generation (for upto 2 clients for 3 months)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                User Management (for upto 2 clients for 3 months)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Profile Management
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Feedback and FAQs access
                            </div>

                        </div>

                        <div className="pricing-card-dollar-row">
                            <div className="pricing-card-dollar">
                                $
                            </div>
                            <div className="pricing-card-dollar-price">
                                0
                            </div>
                            <div className="pricing-card-dollar-month">
                                /month
                            </div>
                        </div>

                        <CalendlyButton/>

                    </div>

                    <div className="pricing-model-card">

                        {/*<div className="pricing-card-best" style={{color: "#FFF"}}>*/}
                        {/*    d*/}
                        {/*</div>*/}

                        <div className="pricing-card-name">
                            Standard
                        </div>

                        {/*<div className="pricing-card-prices">*/}
                        {/*    $ 17/month*/}
                        {/*</div>*/}

                        {/*<div className="pricing-card-prices-subheading">*/}
                        {/*    Per learner, per month billed annually*/}
                        {/*</div>*/}

                        {/*<div className="pricing-card-billed" style={{paddingTop: 10}}>*/}
                        {/*    $ 15 per learner, per month billed annually*/}
                        {/*</div>*/}

                        {/* <div className="get-started-btn" onClick={handleClick}>
                        Get Started
                    </div> */}

                        {/* <CalendlyButton2 /> */}

                        {/*<div className="plans-features-header">*/}
                        {/*    Everything in Starter, plus:*/}
                        {/*</div>*/}

                        <div className="check-list-column">

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Manage up to 10 Clients
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Unlimited Therapist Dashboard
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Unlimited Library Management
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Session Scheduling
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Attendance View Tracking (for up to 10 Clients)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Session Data Collection (upto 10 clients)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Therapy Training (Unlimited courses)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Session Management
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Billing with invoice generation (for upto 10 clients)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                User Management (for upto 10 clients )
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Profile Management
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Feedback and FAQs access
                            </div>

                        </div>

                        <div className="pricing-card-dollar-row">
                            <div className="pricing-card-dollar">
                                $
                            </div>
                            <div className="pricing-card-dollar-price">
                                {isMonthly ? 15 : 17}
                            </div>
                            <div className="pricing-card-dollar-month">
                                /month
                            </div>
                        </div>

                        <div className="book-demo-btn"
                             onClick={() => window.open('https://wa.me/+918655976077', '_blank')}>
                            Contact Sales
                        </div>

                    </div>

                    <div className="pricing-model-card">

                        {/*<div className="pricing-card-best" style={{color: "#FFF"}}>*/}
                        {/*    d*/}
                        {/*</div>*/}

                        <div className="pricing-card-name">
                            Premium
                        </div>

                        {/*<div className="pricing-card-prices">*/}
                        {/*    $ 14/month*/}
                        {/*</div>*/}

                        {/*<div className="pricing-card-prices-subheading">*/}
                        {/*    Per learner, per month billed annually*/}
                        {/*</div>*/}

                        {/*<div className="pricing-card-billed" style={{paddingTop: 10}}>*/}
                        {/*    $ 12 per learner, per month billed annually*/}
                        {/*</div>*/}

                        {/*<div className="plans-features-header">*/}
                        {/*    Everything in Premium, plus:*/}
                        {/*</div>*/}


                        <div className="check-list-column">

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Manage up to 50 Clients.
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Unlimited Therapist Dashboard
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Unlimited Library Management
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Session Scheduling
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Attendance View Tracking (for up to 50 Clients)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Session Data Collection (upto 50 clients)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Therapy Training (Unlimited courses)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Session Management
                            </div>

                            <div className="check-list-row">
                                <svg width="26" height="26" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Billing with invoice generation (for upto 2 clients for 3 months)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                User Management (for upto 50 clients)
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Profile Management
                            </div>

                            <div className="check-list-row">
                                <svg width="16" height="16" viewBox="0 0 10 10" fill="none"
                                     className="pricing-model-card-check-list"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_63_672)">
                                        <path
                                            d="M3.8063 8.99998C3.50209 8.99998 3.21396 8.88397 3.02933 8.68396L0.530572 5.98599C0.453318 5.90267 0.396917 5.80772 0.364595 5.70658C0.332272 5.60544 0.324662 5.50008 0.342198 5.39653C0.359735 5.29298 0.402074 5.19327 0.466796 5.1031C0.531518 5.01293 0.617354 4.93407 0.719396 4.87103C0.821401 4.80772 0.937692 4.76149 1.0616 4.73497C1.18552 4.70846 1.31461 4.70219 1.4415 4.71653C1.56838 4.73086 1.69055 4.76552 1.80101 4.81851C1.91148 4.8715 2.00806 4.94178 2.08522 5.02533L3.72938 6.79921L7.8632 1.3747C8.0003 1.1956 8.21873 1.06824 8.47058 1.02057C8.72243 0.972893 8.98714 1.00879 9.20664 1.12039C9.66332 1.35241 9.80388 1.84389 9.51855 2.21764L4.63433 8.62395C4.55095 8.7338 4.43603 8.82536 4.29953 8.89069C4.16304 8.95602 4.00909 8.99315 3.85106 8.99884C3.83568 8.99999 3.82169 8.99998 3.8063 8.99998V8.99998Z"
                                            fill="currentColor"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_63_672">
                                            <rect width="9.92" height="8.32" fill="white"
                                                  transform="translate(0.0400391 0.839996)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Feedback and FAQs access
                            </div>

                        </div>

                        <div className="pricing-card-dollar-row">
                            <div className="pricing-card-dollar">
                                $
                            </div>
                            <div className="pricing-card-dollar-price">
                                {isMonthly ? 12 : 14}
                            </div>
                            <div className="pricing-card-dollar-month">
                                /month
                            </div>
                        </div>

                        <div className="book-demo-btn"
                             onClick={() => window.open('https://wa.me/+918655976077', '_blank')}>
                            Contact Sales
                        </div>

                    </div>

                    <div className="pricing-model-card">

                        {/*<div className="pricing-card-best" style={{color: "#FFF"}}>*/}
                        {/*    d*/}
                        {/*</div>*/}

                        <div className="pricing-card-name">
                            Custom
                        </div>

                        {/*<div className="pricing-card-elite-subheading">*/}
                        {/*    For companies that need to coordinate, Customise It As Per Your Needs*/}
                        {/*</div>*/}

                        <div className="check-list-row" style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: "center", fontWeight: 'bolder'}}>
                            Connect with us for custom pricing
                        </div>

                        <div className="book-demo-btn"
                             onClick={() => window.open('https://wa.me/+918655976077', '_blank')}>
                            Contact Sales
                        </div>

                        {/*<div className="plans-features-header">*/}
                        {/*    Everything in Premium, plus:*/}
                        {/*</div>*/}


                        {/*<div className="check-list-column">*/}

                        {/*    <div className="check-list-row">*/}
                        {/*        <img src={checkList} alt="" width={18} height={18}/>*/}
                        {/*        Unlimited users allowed*/}
                        {/*    </div>*/}

                        {/*    <div className="check-list-row">*/}
                        {/*        <img src={checkList} alt="" width={18} height={18}/>*/}
                        {/*        Artificial intelligence assistant*/}
                        {/*    </div>*/}

                        {/*    <div className="check-list-row">*/}
                        {/*        <img src={checkList} alt="" width={18} height={18}/>*/}
                        {/*        Unlimited view only user permissions*/}
                        {/*    </div>*/}

                        {/*    <div className="check-list-row">*/}
                        {/*        <img src={checkList} alt="" width={18} height={18}/>*/}
                        {/*        The best support team ever!*/}
                        {/*    </div>*/}

                        {/*    <div className="check-list-row">*/}
                        {/*        <img src={checkList} alt="" width={18} height={18}/>*/}
                        {/*        Adapt the platform to your own branding*/}
                        {/*    </div>*/}

                        {/*</div>*/}

                    </div>

                </div>


                <div className="pricing-terms">
                    Your subscription will continue until you cancel. Cancellation takes effect at the end of your
                    current billing period. Taxes may apply. Offer terms are subject to change.
                </div>

            </div>


        </>
    );
};

export default PricingPlans;