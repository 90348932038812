import React, { useState } from "react";
import "../../style/pricing_page/pricing_page.css";
import arrowUp from "../../assets/landing_page/faq-up.png";
import arrowDown from "../../assets/landing_page/faq-down.png";

const FAQSection = () => {

const [clickedBlock, setClickedBlock] = useState(0);

  const handleBlockClick = (blockNumber) => {
    if(clickedBlock ===  blockNumber){
        setClickedBlock(0);
    }else{
        setClickedBlock(blockNumber);
    }
  };

  return (
    <>

    <div className="faq-section-div">

        <div className="faq-section-heading">
            Frequently Asked Questions
        </div>

        <div className="faq-box-column">


                {/* Question 1 */}


                <div className="pricing-faq-divider">

                </div>

                <div className="faq-title-row" onClick={() => handleBlockClick(1)}>

                    <div className="faq-title-style">
                        How do our plans work?
                    </div>

                    {clickedBlock === 1 ? <img src={arrowUp} alt="" width={24} height={24}/> : <img src={arrowDown} alt="" width={24} height={24}/>}

                </div>

                {clickedBlock === 1 && (
                        <div className="faq-answer-row">
                            <div className="faq-answer-style">
                                Our plans start INR 999 for our Starter Kit for small teams of 10-15 people and you would have to contact sales for our Elite Kit pricing model. 
                            </div>
                        </div>
                )}

                {/* Question 2 */}

                <div className="pricing-faq-divider">

                </div>

                <div className="faq-title-row" onClick={() => handleBlockClick(2)}>

                    <div className="faq-title-style">
                        How do I get started?
                    </div>

                    {clickedBlock === 2 ? <img src={arrowUp} alt="" width={24} height={24}/> : <img src={arrowDown} alt="" width={24} height={24}/>}

                </div>

                {clickedBlock === 2 && (
                        <div className="faq-answer-row">
                            <div className="faq-answer-style">
                                Our plans start INR 999 for our Starter Kit for small teams of 10-15 people and you would have to contact sales for our Elite Kit pricing model. 
                            </div>
                        </div>
                )}

                {/* Question 3 */}

                <div className="pricing-faq-divider">

                </div>

                <div className="faq-title-row" onClick={() => handleBlockClick(3)}>

                    <div className="faq-title-style">
                        What features are included?
                    </div>

                    {clickedBlock === 3 ? <img src={arrowUp} alt="" width={24} height={24}/> : <img src={arrowDown} alt="" width={24} height={24}/>}

                </div>

                {clickedBlock === 3 && (
                        <div className="faq-answer-row">
                            <div className="faq-answer-style">
                                Our plans start INR 999 for our Starter Kit for small teams of 10-15 people and you would have to contact sales for our Elite Kit pricing model. 
                            </div>
                        </div>
                )}


                {/* Question 4 */}

                <div className="pricing-faq-divider">

                </div>

                <div className="faq-title-row" onClick={() => handleBlockClick(4)}>

                    <div className="faq-title-style">
                        How safe is Benchmark?
                    </div>

                    {clickedBlock === 4 ? <img src={arrowUp} alt="" width={24} height={24}/> : <img src={arrowDown} alt="" width={24} height={24}/>}

                </div>

                {clickedBlock === 4 && (
                        <div className="faq-answer-row">
                            <div className="faq-answer-style">
                                Our plans start INR 999 for our Starter Kit for small teams of 10-15 people and you would have to contact sales for our Elite Kit pricing model. 
                            </div>
                        </div>
                )}


                {/* Question 5 */}

                <div className="pricing-faq-divider">

                </div>

                <div className="faq-title-row" onClick={() => handleBlockClick(5)}>

                    <div className="faq-title-style">
                        What is your refund policy?
                    </div>

                    {clickedBlock === 5 ? <img src={arrowUp} alt="" width={24} height={24}/> : <img src={arrowDown} alt="" width={24} height={24}/>}

                </div>

                {clickedBlock === 5 && (
                        <div className="faq-answer-row">
                            <div className="faq-answer-style">
                                Our plans start INR 999 for our Starter Kit for small teams of 10-15 people and you would have to contact sales for our Elite Kit pricing model. 
                            </div>
                        </div>
                )}

                <div className="pricing-faq-divider">

                </div>

        </div>

        

    </div>

    </>
  );
};

export default FAQSection;