import React from "react";
import "../../style/new_landing/new_landing_style.css";
import NewLandingPageKnowMoreRow from "./landing_know_more/know_more_row";

const NewLandingPageKnowMore = () => {
    return (
        <div className="new-landing-know-more">
            <div className="new-landing-know-more-main-heading">
                Know More About Benchmark!
            </div>
            <div className="new-landing-know-more-main-subheading">

                Smart real time data collection process.
                A simplified process for clinicians to generate therapy reports.

            </div>
            <NewLandingPageKnowMoreRow/>
        </div>
    );
};

export default NewLandingPageKnowMore;
