import React, { useState } from "react";
import "../../style/pricing_page/pricing_page.css";

import users from "../../assets/features_page/users.png";
import managingPrograms from "../../assets/features_page/managing-programs.png";
import discount from "../../assets/pricing_page/discount.png";
import managingHelp from "../../assets/features_page/managing-help.png";
import { PopupModal } from "react-calendly";

class CalendlyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div className="offer-div-width">

        <div className="book-demo-btn" onClick={() => this.setState({ isOpen: true })}>
          Book a Demo
        </div>

        {/* <div className="google-book-demo" onClick={() => this.setState({ isOpen: true })}>
          <img src={landingCalendar} alt="" className="landing-calendar-button-image" />
          Book A Free Demo
        </div> */}

        <PopupModal
          url="https://calendly.com/butterflylearnings/30min"
          pageSettings={this.props.pageSettings}
          utm={this.props.utm}
          prefill={this.props.prefill}
          onModalClose={() => this.setState({ isOpen: false })}
          open={this.state.isOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
      </div>
    );
  }
}

const OfferSection = () => {

  return (
    <>

    <div className="offer-section-div">

        <div className="offer-section-heading">
            See What We Offer
        </div>

        <div className="offer-section-subheading">
            Understand the services we provide before signing on to Benchmark. 
        </div>

        <CalendlyButton />

        <div class="pricing-grid-container">
                    <div class="pricing-grid-item">

                        <img src={discount} alt="" width={45} height={45} />

                        <div className="pricing-grid-heading">
                            Low Cost and Discount Options
                        </div>

                        <div className="pricing-grid-subheading">
                            Only pay 40% while you onboard to our platform. You can also find any lower-cost options based on company size and needs. 
                        </div>

                    </div>

                    <div class="pricing-grid-item">

                        <img src={managingHelp} alt="" width={45} height={45} />

                        <div className="pricing-grid-heading">
                            Training and Support
                        </div>

                        <div className="pricing-grid-subheading">
                            We will train your team and answer all your queries, whatever you may need now or in the future as users of Benchmark. 
                        </div>

                    </div>

                    <div class="pricing-grid-item">

                        <img src={users} alt="" width={45} height={45} />

                        <div className="pricing-grid-heading">
                            Easy Staff Plans
                        </div>

                        <div className="pricing-grid-subheading">
                            We love to accommodate your team and cost as per your current or future needs. We cater to your company’s growth and productivity. 
                        </div>

                    </div>

                    <div class="pricing-grid-item">

                        <img src={managingPrograms} alt="" width={45} height={45} />

                        <div className="pricing-grid-heading">
                            VB Mapp Library Resources
                        </div>

                        <div className="pricing-grid-subheading">
                            We include VB Mapp, which is a freely available and customizable library with thousands of program templates, which are also customizable. 
                        </div>

                    </div>

                    <div class="pricing-grid-item">

                        <img src={managingPrograms} alt="" width={45} height={45} />

                        <div className="pricing-grid-heading">
                            Community Library Resources
                        </div>

                        <div className="pricing-grid-subheading">
                            Our platform also includes a free public community library with thousands of program templates, which are also customizable. 
                        </div>

                    </div>

                    
                </div>

    </div>

    </>
  );
};

export default OfferSection;