import { PopupModal } from "react-calendly";
import landingCalendar from "../assets/landing_page/landing_calendar.png";
import "../style/landing_page/landing_page.css";
import React, { useState } from "react";

class CalendlyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div>

        <div className="google-book-demo" onClick={() => this.setState({ isOpen: true })}>
          <img src={landingCalendar} alt="" className="landing-calendar-button-image" />
          Book A Free Demo
        </div>

        <PopupModal
          url="https://calendly.com/butterflylearnings/30min"
          pageSettings={this.props.pageSettings}
          utm={this.props.utm}
          prefill={this.props.prefill}
          onModalClose={() => this.setState({ isOpen: false })}
          open={this.state.isOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
      </div>
    );
  }
}

export default CalendlyButton;