import React, { useState } from "react";
import "../../style/about_page/about_page.css";
import storyLeft from "../../assets/about_page/story-left.png";
import storyRight from "../../assets/about_page/story-right.png";

import story1 from "../../assets/about_page/story-1.png";
import story2 from "../../assets/about_page/story-2.png";
import story3 from "../../assets/about_page/story-3.png";

const StorySection = () => {

  const [cards, setCards] = useState([
    /* Define content for each story card */
    // For example:
    { id: 1, image: story1, date: "Feb 2024", content: "We launched our Benchmark website for sales and marketing purposes. "},
    { id: 2, image: story2, date: "Feb 2024", content: "We launched the first version of our web app for desktop and mobile devices. "},
    { id: 3, image: story3, date: "Oct 2019", content: "We hosted our first webinar with our pediatric neurologists and in-house team of experts. "}
  ]);

  const handleLeftClick = () => {
    setCards(prevCards => {
      // Move last card to the beginning
      const updatedCards = [...prevCards];
      const lastCard = updatedCards.pop();
      updatedCards.unshift(lastCard);
      return updatedCards;
    });
  }

  const handleRightClick = () => {
    setCards(prevCards => {
      // Move first card to the end
      const updatedCards = [...prevCards];
      const firstCard = updatedCards.shift();
      updatedCards.push(firstCard);
      return updatedCards;
    });
  }

  const [currentIndex, setCurrentIndex] = useState(1);

  const mobHandleLeftClick = () => {
    setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const mobHandleRightClick = () => {
    setCurrentIndex(prevIndex => Math.min(prevIndex + 1, cards.length - 1));
  };

  return (
    <>  

        <div className="story-div">
            <div className="story-section-header">
                Our Story
            </div>

            <div className="story-control-row">

            <div className="story-control-button-1" onClick={mobHandleLeftClick}>
                <img src={storyLeft} alt="" width={24} height={24}/>
              </div>

              <div className="story-control-button" onClick={handleLeftClick}>
                <img src={storyLeft} alt="" width={24} height={24}/>
              </div>

              <div className="story-card-container">
                {cards.map(card => (
                    <div key={card.id} className="story-card">

                        <img src={card.image} alt="" className="story-card-image"/>

                        <div className="story-card-date">

                          {card.date}

                        </div>

                        <div className="story-card-content">

                          {card.content}

                        </div>

                    </div>
                ))}
              </div>

          <div className="story-card-container-1">
            <div key={cards[currentIndex].id} className="story-card">
              <img src={cards[currentIndex].image} alt="" className="story-card-image" />
              <div className="story-card-date">{cards[currentIndex].date}</div>
              <div className="story-card-content">{cards[currentIndex].content}</div>
            </div>
          </div>

              <div className="story-control-button-1" onClick={mobHandleRightClick}>
                <img src={storyRight} alt="" width={24} height={24}/>
              </div>

              <div className="story-control-button" onClick={handleRightClick}>
                <img src={storyRight} alt="" width={24} height={24}/>
              </div>

            </div>
        </div>

    </>
  );
};

export default StorySection;