import React, {useEffect, useState} from "react";
import "../style/navbar/navbar.css";
import logo from "../assets/landing_page/logo.png";
import menu from "../assets/landing_page/menu.svg";
import close from "../assets/landing_page/close.svg";
import {BrowserRouter as Router, Route, Link, useNavigate} from 'react-router-dom';
import {PopupModal} from "react-calendly";
import axios from "axios";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CalendlyButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <div>

                <div className="nav-book-button" onClick={() => this.setState({isOpen: true})}>
                    Book A Demo
                </div>

                <PopupModal
                    url="https://calendly.com/butterflylearnings/30min"
                    pageSettings={this.props.pageSettings}
                    utm={this.props.utm}
                    prefill={this.props.prefill}
                    onModalClose={() => this.setState({isOpen: false})}
                    open={this.state.isOpen}
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("root")}
                />
            </div>
        );
    }
}

const NavBar = ({overlayActive, setOverlayActive}) => {
    const [menuStatus, setMenuStatus] = useState(false);

    const toggleMenu = () => {
        setMenuStatus(!menuStatus);
        if (!menuStatus) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    };

    // const [overlayActive, setOverlayActive] = useState(false);

    const toggleOverlay = () => {
        setMenuStatus(false);
        setOverlayActive(!overlayActive);
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const navigate = useNavigate();

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        pincode: "",
        message: ""
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitted(true); // Set form submitted state to true
            if (formData.first_name.trim() === "" || formData.last_name.trim() === "" || formData.phone.trim() === "" || formData.email.trim() === "") {
                // Return if first_name is empty
                return;
            }
            // Make HTTP POST request to the API endpoint with JSON body
            const response = await axios.post(
                "https://benchmark.butterflylearnings.com/clients/contact_us/",
                formData
            );

            toggleOverlay();
            toast.success('Our Team Will Contact You Shortly');
            // Handle success response, e.g., show success message, redirect, etc.
        } catch (error) {
            console.error("Error:", error);
            // Handle error response, e.g., show error message, retry, etc.
        }
    };

    useEffect(() => {
        // Function to initialize the Google Translate element
        const googleTranslateElementInit = () => {
            if (!window.google || !window.google.translate) return; // Ensure Google Translate API is available

            new window.google.translate.TranslateElement({
                pageLanguage: 'en',
                includedLanguages: 'id',
                autoDisplay: false
            }, 'google_translate_element');
        };

        // Dynamically add the Google Translate script, but only once
        const addGoogleTranslateScript = () => {
            if (!document.querySelector("script[src='//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit']")) {
                const script = document.createElement('script');
                script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);
            } else {
                // If the script already exists, directly call the init function
                googleTranslateElementInit();
            }
        };

        // Add the Google Translate script
        window.googleTranslateElementInit = googleTranslateElementInit; // Ensure the function is globally accessible
        addGoogleTranslateScript();
    }, []);

    return (
        <>
            <div className="lp_container">
                <nav className="nav1">
                    <img src={logo} alt="" className="lp_logo" onClick={() => navigate(`/`)}
                         style={{cursor: "pointer"}}/>
                    <ul typeof="none">
                        <li><Link to="/features" className="link">Features</Link></li>
                        <li><Link to="/pricing" className="link">Pricing</Link></li>
                        <li><Link to="/about-us" className="link">About Us</Link></li>
                        <li onClick={() => {
                            toggleOverlay()
                        }} className="link">Contact Us
                        </li>
                    </ul>
                    <CalendlyButton/>
                    <li className="custom-translate-dropdown">
                        <div id="google_translate_element"></div>
                    </li>

                </nav>

                {/* mobile options */}
                <div>
                {menuStatus && (
                        <nav className="nav2">
                            <ul typeof="none">
                                <li>Features</li>
                                <li>Pricing</li>
                                <li>About Us</li>
                            </ul>
                            <button>Contact Us</button>
                        </nav>
                    )}
                </div>


            </div>

            <div className="mobile-lp_container">

                <div className="nav1">
                    <img src={logo} alt="" className="lp_logo" onClick={() => navigate(`/`)}/>


                    <CalendlyButton/>

                    {menuStatus ? (
                        <img
                            src={close}
                            alt=""
                            className="lp_menu_icon"
                            onClick={() => {
                                toggleMenu();
                            }}
                        />
                    ) : (
                        <img
                            src={menu}
                            alt=""
                            className="lp_menu_icon"
                            onClick={() => {
                                toggleMenu();
                            }}
                        />
                    )}
                </div>

                <ToastContainer/>

            </div>

            {menuStatus
                ? (

                    <div className="overlay">
                        <div className="navbar-items">

                            <div className="divider">

                            </div>

                            <Link to="/features" className="menu-items" onClick={toggleMenu}>Features</Link>

                            <div className="divider">

                            </div>

                            <Link to="/pricing" className="menu-items" onClick={toggleMenu}>Pricing</Link>

                            <div className="divider">

                            </div>

                            <Link to="/about-us" className="menu-items" onClick={toggleMenu}>About Us</Link>


                            <div className="divider">

                            </div>

                            <div onClick={() => {
                                toggleOverlay()
                            }} className="contact-us">
                                Contact Us
                            </div>

                            <div className="custom-translate-dropdown">
                                <div id="google_translate_element"></div>
                            </div>

                        </div>
                    </div>


                ) : null
            }

            {
                overlayActive
                    ? (
                        <div className="contact-us-center">
                            <div className="contact-us-overlay">


                                <div className="contact-us-overlay-row-3">
                                    <div className="contact-us-overlay-heading">
                                        Contact Us
                                    </div>

                                    <img
                                        src={close}
                                        alt=""
                                        className="contact-us-overlay-icon"
                                        onClick={() => {
                                            toggleOverlay();
                                        }}
                                    />

                                </div>

                                <div className="contact-us-overlay-subheading">
                                    If you are interested in using Benchmark, fill out the form below and our team will
                                    reach out to you.
                                </div>

                                <div className="contact-us-overlay-label">
                                    First Name*
                                </div>

                                <input
                                    type="text"
                                    className={`contact-us-overlay-textfield ${isSubmitted && formData.first_name.trim() === "" ? "empty" : ""}`}
                                    placeholder=""
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />

                                <div className="contact-us-overlay-label">
                                    Last Name*
                                </div>

                                <input
                                    type="text"
                                    className={`contact-us-overlay-textfield ${isSubmitted && formData.last_name.trim() === "" ? "empty" : ""}`}
                                    placeholder=""
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />

                                <div className="contact-us-overlay-label">
                                    Phone Number*
                                </div>

                                <div className="contact-us-overlay-row">

                                    <div className="contact-us-overlay-textfield-2">
                                        <div className="contact-us-country-code">
                                            +91
                                        </div>
                                    </div>

                                    <input
                                        type="text"
                                        className={`contact-us-overlay-textfield-1 ${isSubmitted && formData.phone.trim() === "" ? "empty" : ""}`}
                                        placeholder=""
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />

                                </div>

                                <div className="contact-us-overlay-label">
                                    Company Email*
                                </div>

                                <input
                                    type="text"
                                    className={`contact-us-overlay-textfield ${isSubmitted && formData.email.trim() === "" ? "empty" : ""}`}
                                    placeholder=""
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />

                                <div className="contact-us-overlay-label">
                                    Business Address Line 1 (Optional)
                                </div>

                                <input
                                    type="text"
                                    className="contact-us-overlay-textfield"
                                    placeholder=""
                                    name="address_1"
                                    value={formData.address_1}
                                    onChange={handleChange}
                                />

                                <div className="contact-us-overlay-label">
                                    Business Address Line 2
                                </div>

                                <input
                                    type="text"
                                    className="contact-us-overlay-textfield"
                                    placeholder=""
                                    name="address_2"
                                    value={formData.address_2}
                                    onChange={handleChange}
                                />

                                <div className="contact-us-overlay-row-1">

                                    <div>
                                        <div className="contact-us-overlay-label">
                                            Pincode
                                        </div>

                                        <input
                                            type="text"
                                            className="contact-us-overlay-textfield"
                                            placeholder=""
                                            name="pincode"
                                            value={formData.pincode}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div>
                                        <div className="contact-us-overlay-label">
                                            City
                                        </div>

                                        <input
                                            type="text"
                                            className="contact-us-overlay-textfield"
                                            placeholder=""
                                            name="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div>
                                        <div className="contact-us-overlay-label">
                                            State
                                        </div>

                                        <input
                                            type="text"
                                            className="contact-us-overlay-textfield"
                                            placeholder=""
                                            name="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                        />
                                    </div>

                                </div>

                                <div className="contact-us-overlay-label" style={{marginTop: 24}}>
                                    What would you like to discuss?*
                                </div>

                                <input
                                    type="text"
                                    className={`contact-us-overlay-textfield ${isSubmitted && formData.message.trim() === "" ? "empty" : ""}`}
                                    placeholder=""
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                />

                                <div className="contact-us-overlay-note">
                                    Fields marked with an asterisk (*) are required.
                                </div>

                                <div className="contact-us-overlay-row-2">

                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />

                                    <div className="contact-us-overlay-terms">
                                        By checking the box and clicking "Submit", you are agreeing to Benchmark’s Privacy
                                        Statement.
                                    </div>

                                </div>

                                <div className="contact-us-overlay-terms">
                                    By submitting this form, you agree that Asana may share this information with its
                                    authorized Solution Partners, and that you may be contacted about this inquiry by Asana
                                    or a partner of Asana.
                                </div>

                                <div className="contact-us-overlay-submit" onClick={handleSubmit}>
                                    Submit
                                </div>


                            </div>
                        </div>
                    )
                    : null
            }


        </>
    );
};

export default NavBar;
