import React, { useState } from "react";
import "../../style/features_page/features_page.css";
import search from "../../assets/features_page/search.png";

import therapyDashboard from "../../assets/features_page/therapist-dashboard.png";
import parentDashboard from "../../assets/features_page/parent-dashboard.png";
import managingPrograms from "../../assets/features_page/managing-programs.png";
import dataCollection from "../../assets/features_page/data-collection.png";
import therapyTraining from "../../assets/features_page/therapy-training.png";
import CalendlyButton from "../calendly";

const TherapyManagement = () => {

  return (
    <>
 
                <div className="management-heading">
                    Therapy Management
                </div>
                
                <div className="management-subheading">
                    Explore the realm of your therapy schedule while maintaining the highest standards of security and privacy.
                </div>

                <div style={{marginTop:14}}>
                    <CalendlyButton />
                </div>

                {/* <div className="management-search">
                    <img src={search} alt="" width={20} height={20} />
                    <input type="text" placeholder="Search features" className="management-search-field"/>
                </div> */}

                <div class="management-grid-container">
                    <div class="management-grid-item">

                        <img src={therapyDashboard} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Therapist Dashboard 
                        </div>

                        <div className="management-grid-subheading">
                            This allows BCBA’s, Behavioral Therapists and Supervisors to add programs, create or add therapy sessions and to view their client list and any client-based data.   
                        </div>

                    </div>

                    <div class="management-grid-item">

                        <img src={parentDashboard} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Parent Dashboard 
                        </div>

                        <div className="management-grid-subheading">
                            This allows parents of the clients to see their child’s profile, programs, sessions and their child’s overall progress. 
                        </div>

                    </div>

                    <div class="management-grid-item">

                        <img src={managingPrograms} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Managing Programs 
                        </div>

                        <div className="management-grid-subheading">
                            This allows BCBA’s, Behavioral Therapists and Supervisors to create, edit and manage libraries, programs that include targets, prompts and prompt hierarchy, phases and tags. 
                        </div>

                    </div>

                    <div class="management-grid-item">

                        <img src={dataCollection} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Data Collection 
                        </div>

                        <div className="management-grid-subheading">
                            Empowering therapists to take data for any therapy session that they are assigned to. 
                        </div>

                    </div>

                    <div class="management-grid-item">

                        <img src={therapyTraining} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Therapy Training
                        </div>

                        <div className="management-grid-subheading">
                            This works as a resource for senior behavioral therapists to train junior therapists or anyone within your organization, who is interested in learning about therapy. 
                        </div>

                    </div>

                </div>

    </>
  );
};

export default TherapyManagement;