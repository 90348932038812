import React from "react";
import "../../style/new_landing/new_landing_style.css";
import mobile from "../../assets/new_landing_page/new_landing_graph.png";
import laptop from "../../assets/new_landing_page/laptop.png";
import macMini from "../../assets/new_landing_page/mac_mini.png";
import check from "../../assets/new_landing_page/check.png";
import whatsapp from "../../assets/features_page/whatsapp.png";

const NewLandingPageDevice = () => {
    return (
        <>
            <div className="new-landing-device-web">
                <div className="new-landing-device-row">
                    <img src={mobile} alt="Banner" className="new-landing-device-mobile"
                         style={{paddingLeft: 56, paddingTop: 56}}/>

                    <div className="new-landing-device-column">

                        <div className="new-landing-device-column-heading">
                            For the therapists, by the therapists!
                        </div>

                        <div className="new-landing-device-column-subheading">
                            Introducing Benchmark, a new web app that will provide your ABA clinic with solutions to
                            manage your practice better. The web app includes all the tools that therapists need to
                            efficiently run ABA therapy sessions.
                        </div>

                        <div className="new-landing-device-column-heading">
                            Say goodbye to paper and hello to convenience with Benchmark!
                        </div>

                        <div className="new-landing-device-column-subheading" style={{paddingTop: 72}}>
                            Benchmark revolves around smart data collection practice. A seamless UX experience, an
                            easy-to-understand app where we determine everything from session summary via WhatsApp,
                            automated graphs, smart parent dashboard app to monitor their kid's performance, and so on.
                        </div>

                        <div className="new-landing-whatsapp-button"
                             onClick={() => window.open('https://wa.me/+918655976077?text=Hi,%20I%20want%20to%20know%20about%20Benchmark%20Therapy%20services', '_blank')}>
                            <img src={whatsapp} alt="Banner" width={24} height={24}/>
                            Whatsapp Us
                        </div>


                    </div>
                </div>

                <div style={{paddingTop: 56}}></div>

                <div className="new-landing-device-row">

                    <div className="new-landing-device-column">

                        <div className="new-landing-device-column-heading">
                            Take a look at Benchmark as the Web app!
                        </div>

                        <div className="new-landing-device-column-subheading" style={{paddingTop: 72}}>
                            We are committed to providing robust solutions with well-versed experience of HIPAA-mandated
                            cybersecurity regulations and certificates, to keep your information private and secure. We
                            are
                            also ISO 27001 certified and GDPR compliant
                        </div>

                        <div className="new-landing-device-column-heading">
                            Benchmark Offers!
                        </div>

                        <div>
                            <div className="new-landing-device-check-row">
                                <img src={check} alt="Banner"
                                     style={{height: 24, width: 24, paddingLeft: 16}}/>

                                <div className="new-landing-device-column-check-subheading">
                                    Therapist Dashboard, Smart Data Collection
                                </div>

                            </div>

                            <div className="new-landing-device-check-row">
                                <img src={check} alt="Banner"
                                     style={{height: 24, width: 24, paddingLeft: 16}}/>

                                <div className="new-landing-device-column-check-subheading">
                                    Scheduling, Attendance Tracking, Billing
                                </div>

                            </div>

                            <div className="new-landing-device-check-row">
                                <img src={check} alt="Banner"
                                     style={{height: 24, width: 24, paddingLeft: 16}}/>

                                <div className="new-landing-device-column-check-subheading">
                                    User Management, Library Management, Courses
                                </div>

                            </div>

                            <div className="new-landing-device-check-row">
                                <img src={check} alt="Banner"
                                     style={{height: 24, width: 24, paddingLeft: 16}}/>

                                <div className="new-landing-device-column-check-subheading">
                                    Parent Mobile Application
                                </div>

                            </div>


                        </div>

                    </div>

                    <img src={laptop} alt="Banner" className="new-landing-device-mobile" style={{paddingRight: 56}}/>


                </div>
            </div>

            <div className="new-landing-device-main-mobile">

                <img src={mobile} alt="Banner" className="new-landing-device-mobile"
                     style={{paddingLeft: 56, paddingTop: 56}}/>

                <div className="new-landing-device-column-heading" style={{paddingTop: 32}}>
                    For the therapists, by the therapists!
                </div>

                <div className="new-landing-device-column-subheading">
                    Introducing Benchmark, a new web app that will provide your ABA clinic with solutions to
                    manage
                    your
                    practice better.
                    The web app includes all the tools that therapists need to efficiently run ABA therapy
                    sessions.
                </div>

                <div className="new-landing-device-column-heading">
                    Say goodbye to paper and hello to convenience with Benchmark!
                </div>

                <div className="new-landing-device-column-subheading">
                    Benchmark revolves around smart data collection practice. A seamless UX experience, an
                    easy-to-understand app where we determine everything from session summary via WhatsApp,
                    automated graphs, smart parent dashboard app to monitor their kid's performance, and so on.
                </div>

                <div style={{paddingTop: 56}}></div>

                <div className="new-landing-whatsapp-button" onClick={() => window.open('https://wa.me/+918655976077?text=Hi,%20I%20want%20to%20know%20about%20Benchmark%20Therapy%20services', '_blank')}>
                    <img src={whatsapp} alt="Banner" width={18} height={18}/>
                    Whatsapp Us
                </div>


                <div className="new-landing-device-column-heading">
                    Take a look at Benchmark as the Web app!
                </div>

                <div className="new-landing-device-column-subheading">
                    We are committed to providing robust solutions with well-versed experience of HIPAA-mandated
                    cybersecurity regulations and certificates, to keep your information private and secure. We
                    are
                    also ISO 27001 certified and GDPR compliant
                </div>

                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <img src={macMini} alt="Banner" className="new-landing-device-mobile" style={{paddingTop: 56}}/>
                </div>

                <div className="new-landing-device-column-heading" style={{paddingTop: 48}}>
                    Benchmark Offers!
                </div>

                <div className="new-landing-device-check-row">
                    <img src={check} alt="Banner"
                         style={{height: 24, width: 24, paddingLeft: 16}}/>

                    <div className="new-landing-device-column-check-subheading">
                        Therapist Dashboard, Smart Data Collection
                    </div>

                </div>

                <div className="new-landing-device-check-row">
                    <img src={check} alt="Banner"
                         style={{height: 24, width: 24, paddingLeft: 16}}/>

                    <div className="new-landing-device-column-check-subheading">
                        Scheduling, Attendance Tracking, Billing
                    </div>

                </div>

                <div className="new-landing-device-check-row">
                    <img src={check} alt="Banner"
                         style={{height: 24, width: 24, paddingLeft: 16}}/>

                    <div className="new-landing-device-column-check-subheading">
                        User Management, Library Management, Courses
                    </div>

                </div>

                <div className="new-landing-device-check-row">
                    <img src={check} alt="Banner"
                         style={{height: 24, width: 24, paddingLeft: 16}}/>

                    <div className="new-landing-device-column-check-subheading">
                        Parent Mobile Application
                    </div>

                </div>
            </div>
        </>
    );
};

export default NewLandingPageDevice;
