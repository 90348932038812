import React from "react";
import "../../../style/new_landing/new_landing_style.css";
import {PopupModal} from "react-calendly";


class CalendlyButtonHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <div>

                <div className="new-landing-book-demo" onClick={() => this.setState({isOpen: true})}>
                    Get a free demo
                </div>

                {/* <div className="google-book-demo" onClick={() => this.setState({ isOpen: true })}>
          <img src={landingCalendar} alt="" className="landing-calendar-button-image" />
          Book A Free Demo
        </div> */}

                <PopupModal
                    url="https://calendly.com/butterflylearnings/30min"
                    pageSettings={this.props.pageSettings}
                    utm={this.props.utm}
                    prefill={this.props.prefill}
                    onModalClose={() => this.setState({isOpen: false})}
                    open={this.state.isOpen}
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("root")}
                />
            </div>
        );
    }
}


const NewLandingBookDemo = ({setOverlayActive}) => {

    const handleClick = () => {
        setOverlayActive(prevStatus => !prevStatus);
    };

    return (
        <div
            className="new-landing-book-demo-row">
            <div className="new-landing-page-hiw" onClick={handleClick}>
                See how it works
            </div>
            <CalendlyButtonHome/>
        </div>

    );
};

export default NewLandingBookDemo;
