import React from "react";
import "../../style/new_landing/new_landing_style.css";
import check from "../../assets/new_landing_page/check.png";
import {PopupModal} from "react-calendly";

class CalendlyButtonConnect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <div>

                <div className="new-landing-connect-button" onClick={() => this.setState({isOpen: true})}>
                    Book a demo
                </div>

                {/* <div className="google-book-demo" onClick={() => this.setState({ isOpen: true })}>
          <img src={landingCalendar} alt="" className="landing-calendar-button-image" />
          Book A Free Demo
        </div> */}

                <PopupModal
                    url="https://calendly.com/butterflylearnings/30min"
                    pageSettings={this.props.pageSettings}
                    utm={this.props.utm}
                    prefill={this.props.prefill}
                    onModalClose={() => this.setState({isOpen: false})}
                    open={this.state.isOpen}
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("root")}
                />
            </div>
        );
    }
}

const NewLandingPageConnectNow = () => {
    return (

        <>
            <div className="new-landing-connect-now-row">
                <div className="new-landing-connect-gradient">
                    <div className="new-landing-connect-heading">
                        Connect with Us Now!
                    </div>

                    <CalendlyButtonConnect />

                </div>
            </div>


            <div className="new-landing-connect-now-row-mobile">
                <div className="new-landing-connect-gradient-mobile">
                    <div className="new-landing-connect-heading">
                        Connect with Us Now!
                    </div>

                    <CalendlyButtonConnect />

                </div>
            </div>

            <div className="new-landing-know-more-main-heading" style={{paddingTop: 36}}>
               White Labelled Mobile App Curated Parents!
            </div>


            <div className="new-landing-connect-now-check-row">
                <img src={check} alt="Banner"
                     style={{height: 24, width: 24, paddingLeft: 16, paddingRight: 8, paddingTop: 8}}/>

                <div className="new-landing-connect-now-check">
                    Insights - Track your kid’s progress using the mobile app!
                </div>

            </div>


            <div className="new-landing-connect-now-check-row">
                <img src={check} alt="Banner"
                     style={{height: 24, width: 24, paddingLeft: 16, paddingRight: 8, paddingTop: 8}}/>

                <div className="new-landing-connect-now-check">
                    Explore - Browse through a library of content curated especially for parents!
                </div>

            </div>


            <div className="new-landing-connect-now-check-row">
                <img src={check} alt="Banner"
                     style={{height: 24, width: 24, paddingLeft: 16, paddingRight: 8, paddingTop: 8}}/>

                <div className="new-landing-connect-now-check">
                    Attendance - Track your kid’s attendance!
                </div>

            </div>
        </>

    );
};

export default NewLandingPageConnectNow;
