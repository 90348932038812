import React, { useState } from "react";
import "../style/terms_page/terms_page.css";
import 'react-toastify/dist/ReactToastify.css';

const NavBar = () => {


  return (
    <>
      

      <div>

        <div className="terms-header">
          Terms & Conditions
        </div>

        <div className="terms-column">

          <div className="terms-subheader-column">

            <div className="terms-subheader">
              Purpose Of Policy
            </div>

            <div className="terms-text">
              The purpose of this policy presents Butterfly Learnings commitment for privacy of user information and sensitive commercial/financial data.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
              Scope of Policy
            </div>

            <div className="terms-text">
              This policy applies to all data that is either owned or managed by Butterfly Learnings.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
              Supporting Documents
            </div>

            <ul className="terms-ul">
                <li className="terms-text">Information Security Policy</li>
            </ul>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
              Responsibilities
            </div>

            <ul className="terms-ul">
                <li className="terms-text">Chief Information Security Officer is responsible for development, implementation, maintenance and enforcement of the policy</li>
                <li className="terms-text">Butterfly Learnings ’s Internal Audit Team is responsible for conducting regular audits to ensure compliance to this policy</li>
                <li className="terms-text">Employees and non-employees of Butterfly Learnings are responsible and/or accountable to ensure adherence to the terms of this policy in the course of their job duties</li>
            </ul>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
              Policy Statements
            </div>

            <div className="terms-text">
              The privacy policy displayed to the user must clearly communicate minimum of the following information:
            </div>

            <ul className="terms-ul">
                <li className="terms-text">Purpose for collection of personal information</li>
                <li className="terms-text">Manner in which the information will be processed</li>
                <li className="terms-text">Controls for protection of personal information</li>

                <li className="terms-text">Usage of tools such as cookies to collect personal information online</li>
                <li className="terms-text">Details of information such as IP address, Domain information captured about the user</li>
                <li className="terms-text">Sharing of information with third parties</li>

                <li className="terms-text">User rights to access of personal information</li>
                <li className="terms-text">Details to contact Butterfly Learnings for queries on processing personal information</li>
                <li className="terms-text">Butterfly Learnings commitment to privacy and security</li>

                <li className="terms-text">Period for which the terms and conditions are valid</li>
                <li className="terms-text">Manner in which the information will be processed</li>
                <li className="terms-text">
                  Policy on external links
                  <ul className="terms-ul" style={{ listStyleType: 'lower-roman' }}> {/* Sublist */}
                    <li>Butterfly Learnings will not use information about user activities on the Internet together with any information that would result in user being identified without his consent.</li>
                    <li>Butterfly Learnings will not associate the information collected by software utilities (cookies, single-pixel gif images) with user name or email address, at the time of the user visiting the sites.</li>
                    <li>Butterfly Learnings will implement policy guidelines to safeguard the privacy of the user identifiable information from unauthorized access or improper use, and will continue to enhance security procedures as new technology becomes available.</li>

                    <li>Butterfly Learnings honour requests from user to review all personally identifiable information maintained in reasonably retrievable form, which currently consists of the employee’s name, address, e-mail address, telephone number and will correct any such information which may be inaccurate. Users may verify that appropriate corrections have been made.</li>
                    <li>Butterfly Learnings may use user identifiable information to investigate and help prevent potentially unlawful activity or activity that threatens the network or otherwise violates the user agreement for that service</li>
                    <li>
                      All kinds of data such as personally identifiable information shared by users shall be:
                        <ul className="terms-ul" style={{ listStyleType: 'upper-alpha'}}>
                          <li>Processed fairly, lawfully and securely</li>
                          <li>Processed in relation to the purpose for which it is collected</li>
                          <li>Maintained up to date and accurate as necessary</li>
                          <li>Retained for no longer than is necessary for the purpose for which it is collected</li>
                          <li>Users shall be provided with at least the following information before collecting personally identifiable information</li>
                          <li>Purposes of processing the information</li>
                          <li>
                            Any further information regarding the specific circumstances in which personal information is collected, such as:
                            <ul className="terms-ul">
                              <li>The recipients of the information</li>
                              <li>Whether submission of information is obligatory or voluntary, as well as the impact of failure to submit such information</li>
                              <li>The existence of the right to access, update or remove personal information</li>
                              <li>Whether personal information will be used for marketing purpose</li>
                            </ul>
                          </li>
                        </ul>
                    </li>
                  </ul>
                </li>

            </ul>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
              Policy Violations
            </div>

            <div className="terms-text">
              Violation of the policy will result in corrective action from the management. Disciplinary action will be consistent with the severity of the incident, as determined by the investigation, and may include, but not limited to
            </div>

            <ul className="terms-ul">
                <li className="terms-text">Loss of access privileges to information assets</li>
                <li className="terms-text">Termination of employment or contract</li>
                <li className="terms-text">Other actions deemed appropriate by management, HR division, Legal division and their relevant policies</li>
            </ul>

            <div className="terms-text">
              Violation or deviation of the policy shall be reported to the service desk and a security incident record has to be created for the further investigation of the incident.
            </div>

          </div>

        </div>

        

      </div>

      

      
    </>
  );
};

export default NavBar;
