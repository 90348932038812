import React, { useState, useEffect } from "react";
import "../../style/about_page/about_page.css";
import NavBar from "../../components/navbar";
import AboutUsHeader from "../../components/about_page/about_header";
import ProductivityHeader from "../../components/about_page/productivity_section";
import ClientSection from "../../components/about_page/client_section";
import TeamSection from "../../components/about_page/team_section";
import StorySection from "../../components/about_page/story_section";
import ContactUsSection from "../../components/about_page/contact-us";
import Footer from "../../components/footer";

const AboutPage = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  return (
    <>
    
        <NavBar overlayActive={overlayActive} setOverlayActive={setOverlayActive}/>
        <AboutUsHeader />
        <ProductivityHeader setOverlayActive={setOverlayActive}/>
        <ClientSection />
        <TeamSection />
        <StorySection />
        <ContactUsSection setOverlayActive={setOverlayActive}/>
        <Footer setOverlayActive={setOverlayActive}/>
    </>
  );
};

export default AboutPage;