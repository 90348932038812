import React, { useState } from "react";
import "../../style/about_page/about_page.css";
import abhishek from "../../assets/about_page/abhishek.png";
import sonam from "../../assets/about_page/sonam.png";
import mayank from "../../assets/about_page/mayank.png";
import anupriya from "../../assets/about_page/anupriya.png";
import snehal from "../../assets/about_page/snehal.png";
import mona from "../../assets/about_page/mona.jpg";
import rakshith from "../../assets/about_page/rakshith.jpg";
import saimitra from "../../assets/about_page/saimitra.jpg";

const TeamSection = () => {

  return (
    <>  

        <div className="team-div">

            <div className="team-section-header">
                Our Team
            </div>

            <div className="team-member-row" style={{ paddingTop:30}}>

                <div className="team-member-card">
                    <div className="team-member-div" style={{ backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), url(${abhishek})` }}>

                    </div>

                    <div className="team-member-name">
                        Abhishek Sen
                    </div>

                    <div className="team-member-position">
                        Benchmark Co-founder
                    </div>
                </div>

                <div className="team-member-card">
                    <div className="team-member-div" style={{ backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), url(${sonam})` }}>

                    </div>

                    <div className="team-member-name">
                        Dr. Sonam Kothari
                    </div>

                    <div className="team-member-position">
                        Benchmark Co-founder
                    </div>
                </div>

                <div className="team-member-card">
                    <div className="team-member-div" style={{ backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), url(${mayank})` }}>

                    </div>

                    <div className="team-member-name">
                        Mayank Tiwari
                    </div>

                    <div className="team-member-position">
                        Benchmark Chief Financial Officer and Head of Accounts Department 
                    </div>
                </div>

            </div>

            <div className="team-member-row" style={{ paddingTop:70}}>

                <div className="team-member-card">
                    <div className="team-member-div" style={{ backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), url(${anupriya})` }}>

                    </div>

                    <div className="team-member-name">
                        Anupriya Bose
                    </div>

                    <div className="team-member-position">
                        User Experience and Interface Designer 
                    </div>
                </div>

                <div className="team-member-card">
                    <div className="team-member-div" style={{ backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), url(${snehal})` }}>

                    </div>

                    <div className="team-member-name">
                        Snehal Bhatt
                    </div>

                    <div className="team-member-position">
                        Benchmark Product Manager
                    </div>
                </div>

                <div className="team-member-card">
                    <div className="team-member-div" style={{ backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), url(${mona})` }}>

                    </div>

                    <div className="team-member-name">
                        Mona Kumari
                    </div>

                    <div className="team-member-position">
                        Benchmark Head of Technical Team 
                    </div>
                </div>

            </div>

            <div className="team-member-row-1" style={{ paddingTop:70}}>

                <div className="team-member-card">
                    <div className="team-member-div" style={{ backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), url(${rakshith})` }}>

                    </div>

                    <div className="team-member-name">
                        Rakshith Ramesh
                    </div>

                    <div className="team-member-position">
                        Developer
                    </div>
                </div>

                <div className="team-member-card">
                    <div className="team-member-div" style={{ backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), url(${saimitra})` }}>

                    </div>

                    <div className="team-member-name">
                        Saimitra Chiliveri
                    </div>

                    <div className="team-member-position">
                        Support
                    </div>
                </div>

            </div>
            

        </div>

    </>
  );
};

export default TeamSection;