import React, { useState, useEffect } from "react";

import "../../style/landing_page/landing_page.css";
import NavBar from "../../components/navbar";
import PrivacyComponent from "../../components/privacy_component";
import Footer from "../../components/footer";

const Privacy = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <>
        <NavBar overlayActive={overlayActive} setOverlayActive={setOverlayActive}/>

        <PrivacyComponent />


        <Footer setOverlayActive={setOverlayActive}/>
    </>
  );
};

export default Privacy;
