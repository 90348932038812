import React, { useState } from "react";
import "../../style/about_page/about_page.css";

const ClientSection = () => {

  return (
    <>  

        <div className="client-section-div">
            
              <div className="client-section-row">
                  <div class="client-section-title-container">
                        <div class="client-section-title">
                            250+
                            <div class="client-section-subtitle">Therapists on board</div>
                        </div>
                        
                  </div>

                  <div className="client-section-divider">

                  </div>

                  <div class="client-section-title-container-1">
                        <div class="client-section-title">
                            1800+
                            <div class="client-section-subtitle">Children in care</div>
                        </div>
                        
                  </div>

                  <div className="client-section-divider">

                  </div>

                  <div class="client-section-title-container-1">
                        <div class="client-section-title">
                            40+
                            <div class="client-section-subtitle">Centers around India</div>
                        </div>
                        
                  </div>
              </div>
            
        </div>

    </>
  );
};

export default ClientSection;