import React, { useState } from "react";
import "../../style/features_page/features_page.css";
import featureHeader from "../../assets/features_page/feature-header.png";
import featureAnalytics from "../../assets/features_page/feature-analytics.png";
import featureClock from "../../assets/features_page/feature-clock.png";
import featureControl from "../../assets/features_page/feature-control.png";
import featureDevice from "../../assets/features_page/feature-device.png";
import featureLock from "../../assets/features_page/feature-lock.png";
import calendar from "../../assets/features_page/calendar.png";
import whatsapp from "../../assets/features_page/whatsapp.png";
import CalendlyButton from "../calendly";

const FeatureShowcase= () => {

    const handleOpenWhatsApp = () => {
        const whatsappUrl = `https://wa.me/+919167063658`;
        window.open(whatsappUrl, '_blank');
    };

  return (
    <>
 
          <div className="feature-block-div">

              <div>
                  <div className="feature-block-column">

                      <div className="feature-block-section">
                          FEATURES
                      </div>

                      <div className="feature-block-heading">
                          The data collection system that moves you forward.
                      </div>

                      <div className="feature-block-subheading">
                          The most versatile and flexible clinical data collection system in ABA.
                      </div>

                      <div className="feature-showcase">
                          <div className="features-row">
                              <div className="features-row-heading">
                                  <img src={featureDevice} width={35} height={35} />

                                  <div className="feature-row-heading-column">
                                      <div class="feature-grid-heading">
                                          Responsive Design
                                      </div>

                                      <div className="feature-grid-subheading">
                                          We have created a web and mobile responsive SaaS platform and a mobile application that works on multiple devices
                                      </div>
                                  </div>
                              </div>

                              <div className="features-row-heading">
                                  <img src={featureLock} width={35} height={35} />

                                  <div className="feature-row-heading-column">
                                      <div class="feature-grid-heading">
                                          HIPAA Compliant
                                      </div>

                                      <div className="feature-grid-subheading">
                                          Data privacy is our top priority. Our HIPAA-compliant platform safeguards against theft and fraud
                                      </div>
                                  </div>
                              </div>

                          </div>

                          <div className="features-row">
                              <div className="features-row-heading">
                                  <img src={featureAnalytics} width={35} height={35} />

                                  <div className="feature-row-heading-column">
                                      <div class="feature-grid-heading">
                                          Data Driven Design
                                      </div>

                                      <div className="feature-grid-subheading">
                                          Our data science practices and AI tools like our custom AI chatbot make our products more precise and user friendly
                                      </div>
                                  </div>
                              </div>

                              <div className="features-row-heading">
                                  <img src={featureControl} width={35} height={35} />

                                  <div className="feature-row-heading-column">
                                      <div class="feature-grid-heading">
                                          Extensive Customization
                                      </div>

                                      <div className="feature-grid-subheading">
                                          Create different stages for targets and phases mixed uniquely, and special rules for switching between them
                                      </div>
                                  </div>
                              </div>

                          </div>

                          <div className="features-row-heading">
                              <img src={featureClock} width={35} height={35} />

                              <div className="feature-row-heading-column">
                                  <div class="feature-grid-heading">
                                      Live Data
                                  </div>

                                  <div className="feature-grid-subheading-1">
                                      See the results show up instantly on your screen while another therapist conducts the trials
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="mobile-feature-showcase">
                          <div className="features-row-heading">
                              <img src={featureDevice} width={35} height={35} />

                              <div className="feature-row-heading-column">
                                  <div class="feature-grid-heading">
                                      Responsive Design
                                  </div>

                                  <div className="feature-grid-subheading-1">
                                      We have created a web and mobile responsive SaaS platform and a mobile application that works on multiple devices
                                  </div>
                              </div>
                          </div>

                          <div className="features-row-heading">
                              <img src={featureLock} width={35} height={35} />

                              <div className="feature-row-heading-column">
                                  <div class="feature-grid-heading">
                                      HIPAA Compliant
                                  </div>

                                  <div className="feature-grid-subheading-1">
                                      Data privacy is our top priority. Our HIPAA-compliant platform safeguards against theft and fraud
                                  </div>
                              </div>
                          </div>


                          <div className="features-row-heading">
                              <img src={featureAnalytics} width={35} height={35} />

                              <div className="feature-row-heading-column">
                                  <div class="feature-grid-heading">
                                      Data Driven Design
                                  </div>

                                  <div className="feature-grid-subheading-1">
                                      Our data science practices and AI tools like our custom AI chatbot make our products more precise and user friendly
                                  </div>
                              </div>
                          </div>

                          <div className="features-row-heading">
                              <img src={featureControl} width={35} height={35} />

                              <div className="feature-row-heading-column">
                                  <div class="feature-grid-heading">
                                      Extensive Customization
                                  </div>

                                  <div className="feature-grid-subheading-1">
                                      Create different stages for targets and phases mixed uniquely, and special rules for switching between them
                                  </div>
                              </div>
                          </div>

                          <div className="features-row-heading">
                              <img src={featureClock} width={35} height={35} />

                              <div className="feature-row-heading-column">
                                  <div class="feature-grid-heading">
                                      Live Data
                                  </div>

                                  <div className="feature-grid-subheading-1">
                                      See the results show up instantly on your screen while another therapist conducts the trials
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="feature-btn-row">
                          <CalendlyButton />

                          <div className="feature-whatsapp-btn" onClick={handleOpenWhatsApp}>

                              <img src={whatsapp} alt="" width={24} height={24} />
                              WhatsApp Us

                          </div>

                      </div>

                  </div>

                  <div className="mob-feature-mobile-image-row">
                    <img src={featureHeader} alt="" width={350} height={675} className="mob-feature-mobile-image" />
                  </div>

              </div>

              <img src={featureHeader} alt="" width={425} height={825} className="feature-mobile-image" />

          </div>

    </>
  );
};

export default FeatureShowcase;