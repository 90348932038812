import React, {useState, useEffect} from "react";
import "../../style/pricing_page/pricing_page.css";
import NavBar from "../../components/navbar";
import PricingPlans from "../../components/pricing_page/pricing_plans";
import OfferSection from "../../components/pricing_page/offers_section";
import PricingFAQSection from "../../components/pricing_page/pricing_faq_section";
import ContactUsSection from "../../components/pricing_page/contact_us";
import Footer from "../../components/footer";

const PricingPage = () => {
    const [overlayActive, setOverlayActive] = useState(false);

    useEffect(() => {
        // Scroll to the top of the page when component mounts or route changes
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
          <NavBar overlayActive={overlayActive} setOverlayActive={setOverlayActive}/>
          <PricingPlans setOverlayActive={setOverlayActive}/>
          <OfferSection/>
          <PricingFAQSection/>
          <ContactUsSection setOverlayActive={setOverlayActive}/>
          <Footer setOverlayActive={setOverlayActive}/>
        </>
    );
};

export default PricingPage;
