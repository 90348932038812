import React, { useState } from "react";
import "../../style/about_page/about_page.css";

const AboutUsHeader = () => {

  return (
    <>  

        <div className="about-header-div">

            <div className="about-header-title">
              Together, We Can Harness the Potential!
            </div>

            <div className="about-header-subtitle">
              We are here to work with you to improve people's lives, providing practice management tools, intelligent data management, easy to use software, and support for both your staff and your clients. Our goal at Benchmark is to make the lives of those in the behavioral health field and those they assist smarter and efficient. Dive into the all in one solution curated for all your needs for therapy business management!
            </div>

        </div>

    </>
  );
};

export default AboutUsHeader;