import React, { useState } from "react";
import "../style/terms_page/terms_page.css";

const NavBar = () => {


  return (
    <>
      

      <div>

        <div className="terms-header">
            Privacy Policy
        </div>

        <div className="privacy-first-text">
            The following Privacy Policy governs the online information collection practices of Butterfly Learnings (“Company,” "we" or "us"). It outlines the types of information that we gather about you while you are using our websites https://www.butterflylearnings.com/ (the "sites") and the ways in which we use this information.
        </div>

        <div className="terms-column">

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                PURPOSE AND SCOPE
            </div>

            <div className="terms-text">
                The Privacy Policy applies primarily to information that we collect online; however, it may apply to some of the data that you provide to us offline and/or through other means, as well (for example, via telephone, or through the mail).
            </div>

            <br/>

            <div className="terms-text">
                The Privacy Policy documents and describes the manner in which our organization collects information from all our Users/Viewers of our services. While providing our services on behalf of our registered users, we collect information related to viewers. The use of information collected on behalf of our Users is governed primarily by our contract for the engagement with them and the privacy policies governing their organization. We are not accountable for the privacy policies and practices undertaken by our users or any other third parties.
            </div>

            <br/>

            <div className="terms-text">
                <span> 
                    Butterfly Learnings use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, including the Limited Use requirements.
                </span>
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                Information We Collect
            </div>

            <div className="terms-text">
                We may collect different data from or about you depending on how you use the sites.
            </div>

            <br/>

            <div className="terms-text">
                Data provided by registered users:<br/>Account creation and login: When you create an account or login into your account, we collect personal information such as first name, last name, email address and password.
            </div>

            <br/>

            <div className="terms-text">
                Data provided by non-registered users:<br />Access pre-existing user’s content :When you request to view a pre-existing user’s content the user may ask for personal information such as email address in order to open a Butterfly Learnings’ link. Butterfly Learnings collects and stores this personal information on behalf of its users. When you provide this personal information, you do so in accordance with that user’s privacy practices and policies. Butterfly Learnings is not responsible for the privacy practices of its users, and only processes this information in accordance with the applicable agreement it has with each user. For any issues pertaining to processing of such personal information, please contact the user directly
            </div>

            <br/>

            <div className="terms-text">
                Data collected through automated means:<br />System data: Technical data about your computer or device, like device type, operation system and the IP address. This data helps us improve the delivery of our web pages and to measure traffic on the site. The collection of data will also depend on the individual settings of your device and software. It is recommended to refer to the policies of your device manufacturer or software provider to learn about information they might share with us.
            </div>

            <br/>

            <div className="terms-text">
                User Generated Data:<br />Account Creation, Login and Usage as a viewer : We collect information and material (user generated content) provided to us voluntarily by our users or the viewers to publish and process on our platform. The user generated content is inclusive of text, photos & videos associated with the accounts/ emails used to create an account with Butterfly Learnings or to access links as viewers.
            </div>

            <br/>

            <div className="terms-text">
                Data Collected from Third Parties:<br />Integration of Third Parties such as Google Drive, OneDrive, Dropbox: When you connect your existing cloud storage such as Google Drive, we access your account on your behalf to get information about your files & folders. When you choose to create a Google Drive file link in Butterfly Learnings, we download the selected file(s) from your Google Drive and store it in our own storage for reliability purposes.
            </div>

            <br/>

            <div className="terms-text">
                Data collected during transactions:<br />Transactional Data :On the basis of types of services requested, Butterfly Learnings also collects information such as:
            </div>

            <ul className="terms-ul">
                <li className="terms-text">Type of service that has been requested</li>
                <li className="terms-text">Order details for such a request</li>
                <li className="terms-text">Payment & transaction information (such as merchant’s name, payment method, chargeable amount for the service)</li>
                <li className="terms-text">Date and time the service was provided For the purpose of promotion codes, name of the person associated with such a code will also be collected</li>
            </ul>

            <div className="terms-text">
                Some information is collected through the use of cookies, web beacons and tracking technologies. Please refer to the Cookie Policy for more details on the type of cookies being used.
            </div>


          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                THE TYPES OF INFORMATION WE COLLECT AND STORE
            </div>

            <div className="terms-text">
                We may collect personal and sensitive personal information about you and store this information in connection with the provision and fulfilment of our services to you. Personal information may include:
            </div>

            <ul className="terms-ul">
                <li className="terms-text">First name and last name</li>
                <li className="terms-text">Email address</li>
                <li className="terms-text">Location</li>
                <li className="terms-text">IP Address</li>
            </ul>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                HOW WE USE YOUR PERSONAL INFORMATION
            </div>

            <div className="terms-text">
                The above personal information may be used for the following purposes:
            </div>

            <ul className="terms-ul">
                <li className="terms-text">To provide you with information about our offerings and/or our periodic newsletters</li>
                <li className="terms-text">To respond to your requests</li>
                <li className="terms-text">For creation or development of business intelligence or data analytics in relation to the offerings provided by us (for this purpose we may share the personal information with certain software or tools available online)</li>
                <li className="terms-text">To manage our relationship with you</li>

                <li className="terms-text">For internal record keeping</li>
                <li className="terms-text">To provide you with authorization to login and use your user account</li>
                <li className="terms-text">To verify your identity</li>
                <li className="terms-text">To maintain correct and up-to-date information about you</li>

                <li className="terms-text">To identify you as a contracting party</li>
                <li className="terms-text">To improve our website</li>
                <li className="terms-text">To enable automated handling of the subscriptions</li>
                <li className="terms-text">To otherwise be able to provide the services to you and To comply with our legal or statutory obligations.</li>
                
            </ul>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                WHO HAS ACCESS TO YOUR DATA WITHIN OUR ORGANIZATION?
            </div>

            <div className="terms-text">
                Within our organization, access to your data is limited to those persons who require access in order to provide you with the products and services, which you purchase from us, to contact you, and to respond to your inquiries, including requests for refund. Those staff members may be on teams such as: marketing, events, development, executive, customer support. Employees only have access to data that is relevant to their team, on a ‘need to know’ basis.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                WHO DO WE SHARE YOUR DATA WITH OUTSIDE OUR ORGANIZATION AND WHY?
            </div>

            <div>
                <div className="terms-text-bold">
                    Processors
                </div>

                <div className="terms-text">
                    We may use service providers & third parties for operating and improving the sites, to assist with certain functions, such as payment processing, email transmission, conducting surveys or contests, data hosting, managing our ads, third-party solutions for marketing and analytics, and some aspects of our technical and customer support. We take measures to ensure that these service providers access, process, and store information about you only for the purposes we authorize, through the execution of Data Processing Agreements or Addenda.
                </div>
            </div>

            <div>
                <div className="terms-text-bold">
                    Authorities
                </div>

                <div className="terms-text">
                    We may access, preserve, and disclose information about you to third parties, including the content of messages if we believe disclosure is in accordance with or required by, applicable law, regulation, legal process, or audits. We may also disclose information about you if we believe that your actions are inconsistent with our Terms of Service or related guidelines and policies, or if necessary to protect the rights, property, or safety of, or prevent fraud or abuse of, Company or others.
                </div>
            </div>

            <div>
                <div className="terms-text-bold">
                    Transfer of business
                </div>

                <div className="terms-text">
                    If we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, personal information could be one of the assets transferred to or acquired by a third party.
                </div>
            </div>


          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                WHY AND FOR HOW LONG DO WE STORE INFORMATION WE COLLECT FROM YOU?
            </div>

            <div className="terms-text">
                We retain certain information collected from you while you are a member on the site, and in certain cases where you have deleted your account, for the following reasons:
            </div>

            <ul className="terms-ul">
                <li className="terms-text">So that you can use our sites</li>
                <li className="terms-text">To ensure that we do not communicate with you if you have asked us not to</li>
                <li className="terms-text">To provide you with a refund, if entitled</li>
                <li className="terms-text">To better understand the traffic to our sites so that we can provide all members with the best possible experience</li>
                <li className="terms-text">To detect and prevent abuse of our sites, illegal activities and breaches of our Terms of Service</li>
                <li className="terms-text">To comply with applicable legal, tax or accounting requirements</li>
            </ul>

            <div className="terms-text">
                We retain user data for as long as necessary for the purposes described above. This means that we retain different categories of data for different periods of time depending on the type of data, the category of user to whom the data relates to, and the purposes for which we collected the data.
            </div>


          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                SECURITY
            </div>

            <div className="terms-text">
                We implement a variety of administrative, managerial, and technical security measures to help protect your personal information. Our Company has various internal control standards which relate specifically to the handling of personal information. These include certain controls to help safeguard the information we collect online. Our employees are trained to understand and comply with these controls and we communicate our Privacy Policy practices and guidelines to our employees. However, while we strive to protect your personal information, you must also take steps to protect your information. We urge you to take every precaution to protect your personal information while you are on the Internet.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                YOUR RIGHTS UNDER THE GDPR
            </div>

            <div className="terms-text">
                You have certain rights if you are within the EU this includes:
            </div>

            <ul className="terms-ul">
                <li className="terms-text">Right to access. This right allows you to obtain a copy of your personal data, as well as other supplementary information.</li>
                <li className="terms-text">Right to restrict processing. You have the right to restrict the processing of your personal data in certain circumstances.</li>
                <li className="terms-text">Right to rectification. You have the right to have any incomplete or inaccurate information we hold about you corrected.</li>
                <li className="terms-text">Right to object to processing. The right to object allows you to stop or prevent us from processing your personal data. This right exists where we are relying on a legitimate interest as the legal basis for processing your personal data. You also have the right to object where we are processing your Personal data for direct marketing purposes.</li>
                <li className="terms-text">Right to erasure. You have the right to ask us to delete or remove personal data when the personal data is no longer necessary for the purpose which you originally collected or processed.</li>
            </ul>

            <div className="terms-text">
                To exercise your rights, you can contact us at require email id
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                YOUR RIGHTS UNDER THE CCPA
            </div>

            <div className="terms-text">
                Users who are California residents have certain rights under the California Consumer Privacy Act, (“CCPA”) included in these rights are:
            </div>

            <ul className="terms-ul">
                <li className="terms-text">Right to know. You have the right to request to know more about the categories and specific pieces of personal information that we have collected about you and access a copy of your personal information.</li>
                <li className="terms-text">Right to deletion. You have the right to request deletion of personal information that we have collected about you.</li>
                <li className="terms-text">Right to non-discrimination. If you choose to exercise any of your rights under CCPA, we will treat you like all other users. In other words, there is no penalty for exercising your rights under CCPA.</li>
                <li className="terms-text">Right to opt-out. You have the right to opt-out of the sale of your personal information. We do not sell personal information. However, the service providers we partner with (for example, our advertising partners) may use technology on the Service that "sells" personal information as defined by the CCPA. If you wish to opt-out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below. Please note that any opt out is specific to the browser you use. You may need to opt out on every browser that you use.</li>
            </ul>

            <div className="terms-text">
                To exercise any of these rights under CCPA, please email require email id. To know more about your rights and how to exercise them visit our Data Subject Request Policy.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                OUR POLICY CONCERNING CHILDREN & THEIR DATA
            </div>

            <div className="terms-text">
                Our sites are not directed to children under the age of sixteen and we do not knowingly collect personally identifiable information from children or distribute such information to third parties. We screen users who wish to provide personal information in order to prevent children from providing such information. If we become aware that we have inadvertently received personally identifiable information from a child, we will delete such information from our records. If we change our practices in the future, we will obtain prior, verifiable parental consent before collecting any personally identifiable information from children.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                LINKS TO OTHER WEBSITES
            </div>

            <div className="terms-text">
                Our sites may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
            </div>

            <br />

            <div className="terms-text">
                Third parties are under no obligation to comply with this Privacy Policy with respect to personal data that you provide directly to those third parties or that those third parties collect for themselves. We do not control the third-party sites that may be accessible through our services. Thus, this Privacy Policy does not apply to information you provide to third-party sites or gathered by the third parties that operate them.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                CHANGES TO THIS POLICY
            </div>

            <div className="terms-text">
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. If we make any material change/s to the Policy, we will notify you via email, through a notification posted on the Services, or as required by applicable law. You can see when the Policy was last updated by checking the date at the bottom of this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective from when they are posted on this page
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                SUBPROCESSORS
            </div>

            <div className="terms-text">
                Bombay Autism Services Pvt Ltd.(“Butterfly Learnings”) uses certain sub-processors and content delivery networks to assist it in providing the Butterfly Learnings Services as described in the Terms of Service (“Terms”). Defined terms used herein shall have the same meaning as defined in the Terms.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                WHAT IS A SUBPROCESSORS?
            </div>

            <div className="terms-text">
                A subprocessor is a third party data processor engaged by Butterfly Learnings, who has or potentially will have access to or process Service Data (which may contain Personal Data). Butterfly Learnings engages different types of sub-processors to perform various functions as explained in the tables below. Butterfly Learnings refers to third parties that do not have access to or process Service Data but who are otherwise used to provide the Services as “subcontractors” and not subprocessors.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                DUE DILIGENCE
            </div>

            <div className="terms-text">
                Butterfly Learnings undertakes to use a commercially reasonable selection process by which it evaluates the security, privacy and confidentiality practices of proposed sub-processors that will or may have access to or process Service Data.
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                CONTRACTUAL SAFEGUARDS
            </div>

            <div className="terms-text">
                Butterfly Learnings requires its sub-processors to satisfy equivalent obligations as those required from Butterfly Learnings (as a Data Processor) as set forth in Butterfly Learnings’s Data Processing Agreement (“DPA”), including but not limited to the requirements to:
            </div>

            <ul className="terms-ul">
                <li className="terms-text">Process Personal Data in accordance with data controller’s (i.e. Subscriber’s) documented instructions (as communicated in writing to the relevant subprocessor by Butterfly Learnings)</li>
                <li className="terms-text">In connection with their subprocessing activities, use only personnel who are reliable and subject to a contractually binding obligation to observe data privacy and security, to the extent applicable, pursuant to applicable data protection laws</li>
                <li className="terms-text">Provide regular training in security and data protection to personnel to whom they grant access to Personal Data</li>
                <li className="terms-text">Implement and maintain appropriate technical and organizational measures (including measures consistent with those to which Butterfly Learnings is contractually committed to adhere insofar as they are equally relevant to the subprocessor’s processing of Personal Data on Butterfly Learnings’s behalf) and provide an annual certification that evidences compliance with this obligation. In the absence of such certification Butterfly Learnings reserves the right to audit the subprocessor</li>
                <li className="terms-text">Promptly inform Butterfly Learnings about any actual or potential security breach</li>
                <li className="terms-text">Cooperate with Butterfly Learnings in order to deal with requests from data controllers, data subjects or data protection authorities, as applicable</li>
            </ul>

            <div className="terms-text">
                This policy does not give Subscribers any additional rights or remedies and should not be construed as a binding agreement. The information herein is only provided to illustrate Butterfly Learnings’s engagement process for sub-processors as well as to provide the actual list of third party sub-processors and content delivery networks used by Butterfly Learnings as of the date of this policy (which Butterfly Learnings may use in the delivery and support of its Services).
            </div>

            <br />

            <div className="terms-text">
                <span>If you are a Butterfly Learnings Subscriber and wish to view our <a href="https://www.butterflylearnings.com/dpa">DPA</a>, please visit DPA</span>
            </div>

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                PROCESS TO ENGAGE NEW SUBPROCESSOR
            </div>

            <div className="terms-text">
                For all Subscribers who have executed Butterfly Learnings’s standard DPA, Butterfly Learnings will provide notice via this policy of updates to the list of sub-processors that are utilized or which Butterfly Learnings proposes to utilize to deliver its Services. Butterfly Learnings undertakes to keep this list updated regularly to enable its Subscribers to stay informed of the scope of subprocessing associated with the Butterfly Learnings Services.
            </div>

            <br />

            <div className="terms-text">
                Pursuant to the DPA, a Subscriber can object in writing to the processing of its Personal Data by a new subprocessor within thirty (30) days after updating of this policy and shall describe its legitimate reasons to object. If a Subscriber does not object during such time period the new subprocessor(s) shall be deemed accepted.
            </div>

            <br />

            <div className="terms-text">
                If a Subscriber objects to the use of a subprocessor pursuant to the process provided under the DPA, Butterfly Learnings shall have the right to cure the objection through one of the following options (to be selected at Butterfly Learnings’s sole discretion):
            </div>

            <ul className="terms-ul">
                <li className="terms-text">Butterfly Learnings will cease to use the subprocessor with regard to Personal Data</li>
                <li className="terms-text">Butterfly Learnings will take the corrective steps requested by Subscriber in its objection (which remove Subscriber’s objection) and proceed to use the subprocessor to process Personal Data</li>
                <li className="terms-text">Butterfly Learnings may cease to provide or Subscriber may agree not to use (temporarily or permanently) the particular aspect of a Butterfly Learnings Service that would involve the use of the subprocessor to process Personal Data.</li>
            </ul>

            <div className="terms-text">
                Termination rights, as applicable and agreed, are set forth exclusively in the DPA.
            </div>

            <br />

            <div className="terms-text">
                The following is an up-to-date list (as of the date of this policy) of the names and locations of Butterfly Learnings sub-processors and content delivery networks:
            </div>

            <br />

            <div className="terms-text-bold">
                INFRASTRUCTURE SUBPROCESSORS - SERVICE DATA STORAGE
            </div>

            <div className="terms-text">
                Butterfly Learnings owns or controls access to the infrastructure that Butterfly Learnings uses to host Service Data submitted to the Services, other than as set forth below. Currently, the Butterfly Learnings production systems for the Services are located in co-location facilities in the Mumbai, India. The Subscriber’s Service Data remains in that region, but may be shifted among data centers within a region to ensure performance and availability of the Services. The following table describes the countries and legal entities engaged in the storage of Service Data by Butterfly Learnings.
            </div>

            <br />

                      <table style={{ borderCollapse: 'collapse' }}>
                          <thead>
                              <tr>
                                  <th>Entity Name</th>
                                  <th>Entity Type</th>
                                  <th>Entity Country</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>AWS India</td>
                                  <td>Cloud service provider</td>
                                  <td>India</td>
                              </tr>
                              <tr>
                                  <td>Google Cloud</td>
                                  <td>Cloud service provider</td>
                                  <td>United States</td>
                              </tr>
                              <tr>
                                  <td>Digital Ocean</td>
                                  <td>Cloud service provider</td>
                                  <td>United States</td>
                              </tr>
                          </tbody>
                      </table>

            <br />

            <div className="terms-text-bold">
                SERVICE SPECIFIC SUBPROCESSORS
            </div>

            <div className="terms-text">
                Butterfly Learnings works with certain third parties to provide specific functionality within the Services. These providers are the Subprocessors set forth below. In order to provide the relevant functionality these Subprocessors access Service Data. Their use is limited to the indicated Services.
            </div>

            <br />

                      <table style={{ borderCollapse: 'collapse' }}>
                          <thead>
                              <tr>
                                  <th>Entity Name</th>
                                  <th>Entity Type</th>
                                  <th>Entity Country</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>Leadsquared</td>
                                  <td>CRM</td>
                                  <td>India</td>
                              </tr>
                              <tr>
                                  <td>Agora</td>
                                  <td>Video Calling</td>
                                  <td>United States</td>
                              </tr>
                              <tr>
                                  <td>Wati</td>
                                  <td>Messaging Gateway</td>
                                  <td>India</td>
                              </tr>
                              <tr>
                                  <td>CC Avenue</td>
                                  <td>Payment gateway</td>
                                  <td>India</td>
                              </tr>
                              <tr>
                                  <td>Firebase</td>
                                  <td>Push Notification</td>
                                  <td>United States</td>
                              </tr>
                              <tr>
                                  <td>AuthKey</td>
                                  <td>SMS Gateway</td>
                                  <td>India</td>
                              </tr>
                          </tbody>
                      </table>

            <br />

            <div className="terms-text-bold">
                CONTENT DELIVERY NETWORKS
            </div>

            <div className="terms-text">
                As explained above, Butterfly Learnings’s Services may use content delivery networks (“CDNs”) to provide the Services, for security purposes, and to optimize content delivery. CDNs do not have access to Service Data but are commonly used systems of distributed services that deliver content based on the geographic location of the individual accessing the content and the origin of the content provider. Website content served to website visitors and domain name information may be stored with a CDN to expedite transmission, and information transmitted across a CDN may be accessed by that CDN to enable its functions. The following describes the use of CDNs by Butterfly Learnings’s Services.
            </div>

            <br />

                      <table style={{ borderCollapse: 'collapse' }}>
                          <thead>
                              <tr>
                                  <th>CDN Provider</th>
                                  <th>CDN Location</th>
                                  <th>Entity Country</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>CloudFront by Amazon Web Services, Inc.</td>
                                  <td>Global</td>
                                  <td>United States</td>
                              </tr>
                          </tbody>
                      </table>

            <br />

            <div className="terms-text-bold">
                Butterfly Learnings Group Sub-Processors
            </div>

            <div className="terms-text">
                The following entities are the current members of the Butterfly Learnings Group. Accordingly, they function as Subprocessors to provide the Services.
            </div>

            <br />

                      <table style={{ borderCollapse: 'collapse' }}>
                          <thead>
                              <tr>
                                  <th>Entity Name</th>
                                  <th>Entity Country</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>Butterfly Learnings</td>
                                  <td>India</td>
                              </tr>
                          </tbody>
                      </table>

            <br />

          </div>

          <div className="terms-subheader-column">

            <div className="terms-subheader">
                CONTACT US
            </div>

            <div className="terms-text">
                If you have any questions about this Privacy Policy, you can contact us at require email id
            </div>

            <br />
            <br />


            <div className="terms-text-bold">
                Last Updated: 06/6/2023
            </div>

          </div>

        </div>

        

      </div>

      

      
    </>
  );
};

export default NavBar;
