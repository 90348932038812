import React, { useState } from "react";
import "../../style/features_page/features_page.css";

import eye from "../../assets/features_page/eye.png";
import email from "../../assets/features_page/mail.png";
import youtube from "../../assets/features_page/youtube.png";
import clipboard from "../../assets/features_page/clipboard.png";
import calendar from "../../assets/features_page/calendar-black.png";
import settings from "../../assets/features_page/settings.png";
import CalendlyButton from "../calendly";

const BusinessPotential = () => {

  return (
    <>
 
          <div className="business-potential-div">
            <div className="business-potential-heading">
                Unlocking Benchmark's Business Potential 
            </div>

            <div className="business-potential-subheading">
                We would love to show you what Benchmark can do to help you reach your business goals. 
            </div>

            <div style={{marginTop:14}}>
                    <CalendlyButton />
            </div>

            <div class="potential-grid-container">
                    <div class="potential-grid-item">

                        <img src={eye} alt="" width={45} height={45} />

                        <div className="potential-grid-heading">
                            Increasing Accessibility
                        </div>

                        <div className="potential-grid-subheading">
                            We breakdown your child’s therapy and progress into smaller chunks of understandable information  
                        </div>

                    </div>

                    <div class="potential-grid-item">

                        <img src={email} alt="" width={45} height={45} />

                        <div className="potential-grid-heading">
                            Easy Data Sharing
                        </div>

                        <div className="potential-grid-subheading">
                            Benchmark helps therapists gather and share data easily with parents, caregivers and preschools 
                        </div>

                    </div>

                    <div class="potential-grid-item">

                        <img src={youtube} alt="" width={45} height={45} />

                        <div className="potential-grid-heading">
                            Assisting Staff Training
                        </div>

                        <div className="potential-grid-subheading">
                            Benchmark facilitates training of junior therapists through a catalog of available resources for therapy training 
                        </div>

                    </div>

                    <div class="potential-grid-item">

                        <img src={calendar} alt="" width={45} height={45} />

                        <div className="potential-grid-heading">
                            Hassle-Free Documentation
                        </div>

                        <div className="potential-grid-subheading">
                            Benchmark allows for creation and processing of required organizational documentation like invoices, session summaries and reports
                        </div>

                    </div>

                    <div class="potential-grid-item">

                        <img src={clipboard} alt="" width={45} height={45} />

                        <div className="potential-grid-heading">
                            Flexible Scheduling
                        </div>

                        <div className="potential-grid-subheading">
                            Anyone from your team (not just therapists but sales or reception staff) can freely add or edit any scheduled sessions or staff availability
                        </div>

                    </div>

                    <div class="potential-grid-item">

                        <img src={settings} alt="" width={45} height={45} />

                        <div className="potential-grid-heading">
                            User Control  
                        </div>

                        <div className="potential-grid-subheading">
                            Benchmark has a system in place where user roles and permissions can be added and managed to avoid any data or system tampering 
                        </div>

                    </div>
                </div>

          </div>

    </>
  );
};

export default BusinessPotential;