import React, {useState, useEffect} from "react";
import NewLandingPageHeader from "../../components/new_landing_page/landing_header";
import ClientSection from "../../components/about_page/client_section";
import NewLandingPageDevice from "../../components/new_landing_page/landing_device";
import NewLandingPageKnowMore from "../../components/new_landing_page/landing_know_more";
import NewLandingPageConnectNow from "../../components/new_landing_page/landing_connect_now";
import DownloadSection from "../../components/features_page/download_section";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";

const NewLandingPage = () => {

    const [overlayActive, setOverlayActive] = useState(false);

    useEffect(() => {
        // Scroll to the top of the page when component mounts or route changes
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar overlayActive={overlayActive} setOverlayActive={setOverlayActive}/>
            <NewLandingPageHeader setOverlayActive={setOverlayActive}/>
            <ClientSection/>
            <NewLandingPageDevice/>
            <NewLandingPageKnowMore/>
            <NewLandingPageConnectNow/>
            <div style={{marginBottom: 56}}></div>
            <DownloadSection/>
            <div className="new-landing-mob-height "></div>
            <Footer setOverlayActive={setOverlayActive}/>
        </>
    );
};

export default NewLandingPage;
