import React from "react";
import "../../../style/new_landing/new_landing_style.css";

const NewLandingPageHeaderSubTitle = () => {
  return (
      <div className="new-landing-header-subtitle">
          Get ready to simplify your therapy data management with Benchmark. <br/> If you’re an ABA therapist or a Therapy Organization, this web app is a must!
      </div>
  );
};

export default NewLandingPageHeaderSubTitle;
