import React from "react";
import "../../../style/new_landing/new_landing_style.css";
import know1 from "../../../assets/new_landing_page/know1.png";
import know2 from "../../../assets/new_landing_page/know2.png";
import know3 from "../../../assets/new_landing_page/know3.png";
import know4 from "../../../assets/new_landing_page/know4.png";
import know5 from "../../../assets/new_landing_page/know5.png";

const NewLandingPageKnowMoreRow = () => {
    return (
        <div className="new-landing-know-more-row">
            <div className="new-landing-know-more-card">
                <img src={know1} alt="Banner" className="new-landing-know-more-img"/>

                <div className="new-landing-know-more-heading">Better Access to Data</div>

                <div className="new-landing-know-more-subheading">From user management to authentication and
                    authorization, Benchmark empowers data with granular control over data access and resources,
                    ensuring
                    compliance and security.
                </div>

            </div>
            <div className="new-landing-know-more-card">
                <img src={know2} alt="Banner" className="new-landing-know-more-img"/>

                <div className="new-landing-know-more-heading">Smart Data Collection</div>

                <div className="new-landing-know-more-subheading">Benchmark follows a smart, fast and efficient process
                    of real-time data collection. Easy access to data for therapists individually using the therapist
                    dashboard.
                </div>

            </div>
            <div className="new-landing-know-more-card">
                <img src={know3} alt="Banner" className="new-landing-know-more-img"/>

                <div className="new-landing-know-more-heading">Manage Practices Efficiently</div>

                <div className="new-landing-know-more-subheading">With BenchMark's practice management, you can ensure
                    efficient practices such as Billing, Scheduling, User Management.
                </div>


            </div>
            <div className="new-landing-know-more-card">
                <img src={know4} alt="Banner" className="new-landing-know-more-img"/>

                <div className="new-landing-know-more-heading">Ensure Data Privacy</div>

                <div className="new-landing-know-more-subheading">Effortless data privacy for your digital activities
                    and a well-versed experience of HIPAA-mandated cybersecurity regulations and certificates, to keep
                    your information private and secure. We are also ISO 27001 certified and GDPR compliant
                </div>

            </div>
            <div className="new-landing-know-more-card">
                <img src={know5} alt="Banner" className="new-landing-know-more-img"/>

                <div className="new-landing-know-more-heading">Boost Therapy Results</div>

                <div className="new-landing-know-more-subheading">A simplified process for clinicians to generate
                    therapy reports.
                </div>

            </div>
        </div>
    );
};

export default NewLandingPageKnowMoreRow;
