import "./App.css";
import Features from "./pages/features_page/features_page";
import Pricing from "./pages/pricing_page/pricing_page";
import About from "./pages/about_page/about_page";
import Terms from "./pages/terms_page/terms";
import Privacy from "./pages/privacy_page/privacy";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import NewLandingPage from "./pages/new_landing/new_landing_page";
import {useEffect} from "react";

function App() {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<NewLandingPage/>}/>
                    <Route path="/features" element={<Features/>}/>
                    <Route path="/pricing" element={<Pricing/>}/>
                    <Route path="/about-us" element={<About/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
