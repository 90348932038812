import React, { useState } from "react";
import "../../style/about_page/about_page.css";

const ContactUsSection = ({setOverlayActive}) => {

  const handleClick = () => {
    setOverlayActive(prevStatus => !prevStatus);
  };

  return (
    <>  

        <div className="contact-us-div">
            <div className="contact-us-heading">
                Have an idea in mind? <br /> Let’s get to work. 
            </div>

            <div className="contact-us-button" onClick={handleClick}>
                Contact Us
            </div>
        </div>

    </>
  );
};

export default ContactUsSection;