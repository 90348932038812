import React, { useState } from "react";
import "../../style/features_page/features_page.css";
import search from "../../assets/features_page/search.png";

import managingBilling from "../../assets/features_page/managing-billing.png";
import scheduling from "../../assets/features_page/calendar-black.png";
import users from "../../assets/features_page/users.png";
import managingPrograms from "../../assets/features_page/managing-programs.png";
import managingSignatures from "../../assets/features_page/managing-signatures.png";
import managingHelp from "../../assets/features_page/managing-help.png";
import CalendlyButton from "../calendly";

const PracticeManagement = () => {

  return (
    <>
 
                <div className="management-heading">
                    Practice Management
                </div>

                <div className="management-subheading">
                    The relevant feature of running a practice that becomes and remains a joy for you and the client.
                </div>

                <div style={{marginTop:14}}>
                    <CalendlyButton />
                </div>

                {/* <div className="management-search">
                    <img src={search} alt="" width={20} height={20} />
                    <input type="text" placeholder="Search features" className="management-search-field"/>
                </div> */}

                <div class="management-grid-container">
                    <div class="management-grid-item">

                        <img src={managingBilling} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Billing
                        </div>

                        <div className="management-grid-subheading">
                            This allows your financial team or accounts department to generate, edit and manage client and/or vendor invoices.  
                        </div>

                    </div>

                    <div class="management-grid-item">

                        <img src={scheduling} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Scheduling
                        </div>

                        <div className="management-grid-subheading">
                            This allows BCBA’s, Behavioral Therapists and Supervisors to add and edit availability for therapists as well add and edit pre-assessment and therapy sessions. 
                        </div>

                    </div>

                    <div class="management-grid-item">

                        <img src={users} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Managing Users
                        </div>

                        <div className="management-grid-subheading">
                            This allows any organization to create user roles and enable permissions for all types of users. 
                        </div>

                    </div>

                    <div class="management-grid-item">

                        <img src={managingPrograms} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Managing Sessions
                        </div>

                        <div className="management-grid-subheading">
                            This allows any organization to create, edit, delete or add any session type as per their business needs. 
                        </div>

                    </div>

                    <div class="management-grid-item">

                        <img src={managingSignatures} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Managing Signatures
                        </div>

                        <div className="management-grid-subheading">
                            This allows any organization to upload documents, signatures, logos and consolidate any type of documentation.
                        </div>

                    </div>

                    <div class="management-grid-item">

                        <img src={managingHelp} alt="" width={45} height={45} />

                        <div className="management-grid-heading">
                            Help and Feedback 
                        </div>

                        <div className="management-grid-subheading">
                            This is system allows users to get support for their doubts and also provide feedback for any necessary systematic changes. 
                        </div>

                    </div>
                </div>

    </>
  );
};

export default PracticeManagement;